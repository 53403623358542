export class NumberHelper {
  static parseToNumber<T>(value: T, defaultValue = 0): number {
    const parsedValue = Number(value);
    return isNaN(parsedValue) ? defaultValue : parsedValue;
  }
  static getNumberOrValue<T, D>(value: T, otherValue: D) {
    return typeof value === 'number' ? value : otherValue;
  }
  static randomFromInterval(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
}
