import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useStyles } from './styles';

const ConfirmDialog = ({ onClose, open, onCancel, onApprove, cancelText, title, approveText }) => {
  const styles = useStyles();
  const [isLoading, setLoading] = useState(false);

  const onApproveClick = async () => {
    setLoading(true);
    await onApprove();
    setLoading(false);
  };

  return (
    <div>
      <Dialog onClose={onClose} className={styles.dialog} open={open}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogActions>
            <Button color="secondary" variant="contained" onClick={onCancel} disabled={isLoading}>
              {cancelText}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={onApproveClick}
              disabled={isLoading}
            >
              {approveText}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ConfirmDialog;
