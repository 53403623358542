export const notificationClickTypes = {
  internal_redirect: 'internal_redirect',
  external_redirect: 'external_redirect',
  custom_action: 'custom_action',
};

export const notificationType = {
  individual: 'individual',
  global: 'global',
};

export const notificationActionNames = {
  reward_rank_progression: 'reward_rank_progression',
};

export const emptyAction = {
  tagType: 'button',
  actionType: 'internal_redirect',
  actionProperties: {
    tagLabel: '',
    path: '',
    link: '',
    actionName: '',
  },
};

export const clickTypeValueLabels = {
  [notificationClickTypes.internal_redirect]: 'Внутреннее перенаправление',
  [notificationClickTypes.external_redirect]: 'Внешнее перенаправление',
  [notificationClickTypes.custom_action]: 'Пользовательское действие',
};
export const actionTypeLabels = {
  [notificationType.global]: 'Общий',
  [notificationType.individual]: 'Индивидуальный',
};
export const actionNamesLabels = {
  [notificationActionNames.reward_rank_progression]: 'Награда за повышение ранга',
};
