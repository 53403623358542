import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function B4({ width, height }) {
  return (
    <SVGUniqueID>
      <svg
        id='b-04_svg__Layer_1'
        x={0}
        y={0}
        viewBox='0 0 70 70'
        xmlSpace='preserve'
        width={width}
        height={height}
      >
        <style>{'.b-04_svg__st12{fill:#d8d5b6}'}</style>
        <linearGradient
          id='b-04_svg__SVGID_1_'
          gradientUnits='userSpaceOnUse'
          x1={35}
          y1={69.5}
          x2={35}
          y2={0.5}
        >
          <stop offset={0} stopColor='#bcc5c7' />
          <stop offset={0.65} stopColor='#e6e8e9' />
        </linearGradient>
        <path
          d='M10 69.5C4.8 69.5.5 65.2.5 60V10C.5 4.8 4.8.5 10 .5h50c5.2 0 9.5 4.3 9.5 9.5v50c0 5.2-4.3 9.5-9.5 9.5H10z'
          fill='url(#b-04_svg__SVGID_1_)'
        />
        <path
          d='M60 1c5 0 9 4 9 9v50c0 5-4 9-9 9H10c-5 0-9-4-9-9V10c0-5 4-9 9-9h50m0-1H10C4.5 0 0 4.5 0 10v50c0 5.5 4.5 10 10 10h50c5.5 0 10-4.5 10-10V10c0-5.5-4.5-10-10-10z'
          fill='#fff'
        />
        <g>
          <linearGradient
            id='b-04_svg__SVGID_2_'
            gradientUnits='userSpaceOnUse'
            x1={11.377}
            y1={36.064}
            x2={58.623}
            y2={36.064}
          >
            <stop offset={0} stopColor='#3a3a3f' />
            <stop offset={1} stopColor='#040408' />
          </linearGradient>
          <path
            d='M47.8 51.9c-7.4 2.7-8.8-1.3-8.8-1.3s4.7-.7 6.1-2l-3.4-7.4s6.9 0 6.1-8.8c-.6-6.2-9.4-9.1-10.1-12.8 0-.1 0-.2-.1-.2.9-.7 1.4-1.7 1.4-2.8 0-2.1-1.8-3.7-4-3.7s-4 1.7-4 3.7c0 1.1.5 2.1 1.4 2.8 0 .1 0 .1-.1.2-.7 3.4-9.4 6.7-10.1 12.8-1.1 8.7 6.1 8.8 6.1 8.8l-3.4 7.4c1.3 1.3 6.1 2 6.1 2s-1.3 4-8.8 1.3c-7.2-2.6-10.8 1.3-10.8 1.3l2.7 6.1s2.7-2 5.4-2 3.4 1.3 8.8 1.3 6.1-4.7 6.1-4.7H35.7s.7 4.7 6.1 4.7 6.1-1.3 8.8-1.3 5.4 2 5.4 2l2.7-6.1c-.1.1-3.6-3.9-10.9-1.3z'
            fill='url(#b-04_svg__SVGID_2_)'
          />
          <linearGradient
            id='b-04_svg__SVGID_3_'
            gradientUnits='userSpaceOnUse'
            x1={35}
            y1={61.679}
            x2={35}
            y2={11.278}
          >
            <stop offset={0.001} stopColor='#fff' />
            <stop offset={1} stopColor='#a4b0b2' />
          </linearGradient>
          <path
            d='M35 12.8c2.2 0 4 1.7 4 3.7 0 1.1-.5 2.1-1.4 2.8 0 .1 0 .1.1.2.8 3.8 9.5 6.6 10.1 12.8.8 8.7-6.1 8.8-6.1 8.8l3.4 7.4c-1.3 1.3-6.1 2-6.1 2s.8 2.3 4.3 2.3c1.2 0 2.6-.3 4.5-.9 1.8-.7 3.4-.9 4.7-.9 4.1 0 6.1 2.2 6.1 2.2l-2.7 6.1s-2.7-2-5.4-2-3.4 1.3-8.8 1.3-6.1-4.7-6.1-4.7H34.3s-.7 4.7-6.1 4.7c-5.4 0-6.1-1.3-8.8-1.3s-5.4 2-5.4 2l-2.7-6.1s2-2.2 6.1-2.2c1.3 0 2.9.2 4.7.9 1.9.7 3.3.9 4.5.9 3.5 0 4.3-2.3 4.3-2.3s-4.7-.7-6.1-2l3.4-7.4s-7.2-.1-6.1-8.8c.8-6.1 9.4-9.4 10.1-12.8 0-.1 0-.2.1-.2-.9-.7-1.4-1.7-1.4-2.8.1-2.1 1.9-3.7 4.1-3.7m0-1.5c-3.1 0-5.5 2.3-5.5 5.2 0 1.2.4 2.3 1.2 3.2-.5.9-1.9 2.1-3.2 3.1-2.8 2.3-6.2 5.2-6.8 9.4-.4 3.3.2 6 1.8 7.8 1.1 1.3 2.4 1.9 3.6 2.3L23.5 48l-.4.9.7.7c.8.8 2.1 1.3 3.5 1.7h-.6c-1.1 0-2.5-.3-4-.8-1.8-.7-3.6-1-5.2-1-4.7 0-7.1 2.6-7.2 2.7l-.7.8.4.9 2.7 6.1.8 1.7 1.5-1.1c.6-.5 2.7-1.7 4.5-1.7.9 0 1.5.2 2.4.4 1.4.4 3 .9 6.4.9 3.7 0 5.7-1.9 6.7-3.7 1 1.8 3.1 3.7 6.7 3.7 3.4 0 5.1-.5 6.4-.9.8-.3 1.5-.4 2.4-.4 1.8 0 3.9 1.2 4.5 1.7l1.5 1.1.8-1.7 2.7-6.1.4-.9-.6-.7c-.1-.1-2.5-2.7-7.2-2.7-1.7 0-3.4.3-5.2 1-1.5.6-2.9.8-4 .8h-.6c1.3-.4 2.7-.9 3.5-1.7l.7-.7-.4-.9-2.6-5.7c1.1-.3 2.3-1 3.4-2.1 1.7-1.9 2.4-4.5 2-7.9-.4-4.2-3.8-7-6.6-9.2-1.4-1.1-2.8-2.3-3.4-3.3.8-.9 1.2-2 1.2-3.2-.1-3.1-2.5-5.4-5.6-5.4z'
            fill='url(#b-04_svg__SVGID_3_)'
          />
          <path
            className='b-04_svg__st12'
            d='M38.4 31H36v-2.4c0-.5-.4-1-1-1-.5 0-1 .4-1 1V31h-2.4c-.5 0-1 .4-1 1v.1c0 .5.4 1 1 1H34v2.4c0 .5.4 1 1 1 .5 0 1-.4 1-1V33h2.4c.5 0 1-.4 1-1s-.5-1-1-1zM28.9 43.2l1.3-2.1s4.7-1.4 9.4 0l1.3 2.1c.2 0-5.2-1.4-12 0zM26.9 47.9l1.1-2.5s7.6-1.7 13.9 0l1.1 2.5c.1 0-6.2-2.6-16.1 0z'
          />
        </g>
      </svg>
    </SVGUniqueID>
  )
}
