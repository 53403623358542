import { useEffect } from 'react';

import endpoints from 'api/endpoints';

import { useAPI } from './use-api';

export const useRanks = () => {
  const [{ data, isLoading, isError }, doGetRanks] = useAPI();

  useEffect(() => {
    doGetRanks(endpoints.ranks);
  }, []);

  return {
    data,
    isLoading,
    isError,
  };
};
