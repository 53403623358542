import endpoints from 'api/endpoints';
import { api } from 'api/rest';

export class PaymentService {
  static async findAll(params) {
    const data = await api.get(endpoints.payments, { params });
    return data;
  }
  static async reject(data) {
    const response = await api.post(endpoints.rejectPayment, data);
    return response;
  }
  static async approve(id) {
    const response = await api.post(endpoints.approvePayment, { id });
    return response;
  }
}
