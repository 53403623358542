import endpoints from 'api/endpoints';
import { api } from 'api/rest';

export class PaymentMethodService {
  static async getPaymentMethods() {
    const data = await api.get(endpoints.paymentMethods);
    return data;
  }

  static async create(body) {
    const data = await api.post(endpoints.paymentMethods, body);
    return data;
  }

  static async deleteById(id, body) {
    const data = await api.delete(`${endpoints.paymentMethods}/${id}`, body);
    return data;
  }

  static async updateById(id, body) {
    const data = await api.put(`${endpoints.paymentMethods}/${id}`, body);
    return data;
  }
}
