import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FastChessboard } from 'components/fast-chessboard';
import { Dices } from 'components/dices';
import { PlayerInfo } from 'components/player-info';

import { parser, helper } from 'common';

import { Grid } from '@material-ui/core';

import endpoints from 'api/endpoints';
import { useAPI } from 'hooks/use-api';
import { BeatLoader } from 'react-spinners';

import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import { getPopulatedPlayer } from 'utils/game';

import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import { STATUS_MESSAGES, CAUSE_MESSAGES } from 'types/enums';
import Player from 'components/Player';

import moment from 'moment';

const fenParser = parser(helper());

const charToNum = {
  P: 1,
  N: 2,
  B: 3,
  R: 4,
  Q: 5,
  K: 6,
};

function getPlayerColor(game, index) {
  return game.players[index].color === 'w' ? 'white' : 'black';
}

function Report({ game }) {
  if (!game) return [];

  const { populate_players, players } = game;

  const { created, status, result, settings, history } = game;

  return (
    <Grid container direction="columns" justify="center" alignItems="center">
      <Grid xs={12}>ID: {game._id} </Grid>
      <Grid xs={12}>Дата создания: {moment(created).format('HH:mm:ss DD-MM-YYYY')}</Grid>
      <Grid xs={12}>Кол-во "ходов": {history.length - 1}</Grid>
      <Grid xs={12}>Статус: {STATUS_MESSAGES[status]}</Grid>
      <Grid xs={12}>Причина: {CAUSE_MESSAGES[result.cause]}</Grid>
      <Grid xs={12}>Ставка: {settings.bet}</Grid>
      <Grid xs={12}>Тип игры: {settings.doubling ? 'С удовением' : 'Классика'}</Grid>
      <Grid xs={12}>
        Время: {settings.timeControl.time}м{settings.timeControl.increment}с
      </Grid>
      <Grid xs={12}>Победитель: {result.winner === 'w' ? 'Белые' : 'Черные'}</Grid>
      <Grid xs={12}>
        {players[0] && (
          <Player
            player={{
              ...getPopulatedPlayer(players[0], populate_players),
              color: players[0].color,
            }}
          />
        )}
      </Grid>
      <Grid xs={12}>
        {players[1] && (
          <Player
            player={{
              ...getPopulatedPlayer(players[1], populate_players),
              color: players[1].color,
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}

const INITIAL_FEN = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR - KQkq - -';

function getOnlyFEN(fen) {
  return fen.split(' ')[0];
}

function getOnlyColorAndDices(fen) {
  const parts = fen.split(' ');

  return { color: parts[1], dicePieces: parts[4] };
}

export default function Game() {
  const params = useParams();

  const [game, setGame] = useState();

  const [diceColor, setDiceColor] = useState('w');
  const [dices, setDices] = useState([1, 1, 1]);
  const [prevDices, setPrevDices] = useState([1, 1, 1]);

  const [isPlay, setPlay] = useState(false);

  const [intervalId, setIntervalId] = useState();

  const [positionHistory, setPositionHistory] = useState(0);

  const [{ data, isLoading, isError }, doGetGame] = useAPI();

  useEffect(() => {
    doGetGame(endpoints.game + '/' + params.gameId);
  }, []);

  useEffect(() => {
    if (!isError && data) {
      setGame(data);
      setPositionHistory(data.history.length - 1);
    }
  }, [data]);

  useEffect(() => {
    if (!game) return;

    if (positionHistory === game.history.length - 1) {
      setPositionHistory(0);
    }
  }, [isPlay, game]);

  useEffect(() => {
    if (!isPlay) return;

    if (positionHistory === game.history.length - 1) {
      clearInterval(intervalId);
      setPlay(false);
    }
  }, [positionHistory]);

  useEffect(() => {
    if (!positionHistory) return;
    const { color, dicePieces } = getOnlyColorAndDices(game.history[positionHistory]);

    if (dicePieces.length === 3) {
      const currentDices = dicePieces.split('').map((w) => charToNum[w.toUpperCase()]);
      setDices(currentDices);
      setPrevDices(currentDices);
      setDiceColor(color);
    }
  }, [positionHistory]);

  useEffect(() => {
    if (!isPlay) return;

    if (positionHistory === 0) {
      const { color, dicePieces } = getOnlyColorAndDices(game.history[0]);
      const currentDices = dicePieces.split('').map((w) => charToNum[w.toUpperCase()]);
      console.log(dicePieces);
      setDices(currentDices);
      setPrevDices(currentDices);
    }
    const intervalId = setInterval(() => {
      setPositionHistory((positionHistory) => positionHistory + 1);
    }, 1000);

    setIntervalId(intervalId);

    return () => clearInterval(intervalId);
  }, [isPlay]);

  function handleChange(e, value) {
    setPositionHistory(value);
  }

  if (!game) {
    return (
      <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
          }}
        >
          <BeatLoader size={16} color="#00acc1" loading={true} />
        </div>
      </div>
    );
  }

  const killedPieces = fenParser.getKilledPieces(
    game.history.slice(0, positionHistory),
    game.history[positionHistory],
  );

  if (!game.result) return <div>Игра с номером {params.gameId} еще не имеет результата!</div>;

  return (
    <Grid
      container
      direction="columns"
      justify="center"
      alignItems="center"
      style={{ backgroundColor: 'white', padding: 40 }}
    >
      <Grid xs={6} style={{ minWidth: 360 }}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={4}
          style={{
            width: 300,
            paddingBottom: 20,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          {positionHistory > 0 ? (
            <Dices
              activeColor={diceColor}
              figures={true}
              noanimation={true}
              lastDices={prevDices}
              currentPlayerDice={[
                { active: true, figure: dices[0] },
                { active: true, figure: dices[1] },
                { active: true, figure: dices[2] },
              ]}
            />
          ) : (
            <div
              style={{
                backgroundColor: 'white',
                display: 'flex',
                opacity: '0%',
                height: '60px',
                padding: '16px',
              }}
            ></div>
          )}
        </Grid>

        <Grid container direction="columns" justify="center" alignItems="center">
          <Grid item xs={12} alignItems="center" style={{ height: 60 }}>
            <PlayerInfo
              name={
                getPopulatedPlayer(game.players[0], game.populate_players)?.email ?? 'Anonymous'
              }
              color={getPlayerColor(game, 0)}
              killedPieces={killedPieces[getPlayerColor(game, 1)]}
            />
          </Grid>
          <Grid item xs={12}>
            <FastChessboard
              orientation={game.players[1].color}
              fen={
                game
                  ? game.history.length > 1
                    ? getOnlyFEN(game.history[positionHistory])
                    : INITIAL_FEN
                  : undefined
              }
            />
          </Grid>
          <Grid item xs={12} style={{ height: 60 }}>
            <PlayerInfo
              name={
                getPopulatedPlayer(game.players[1], game.populate_players)?.email ?? 'Anonymous'
              }
              color={getPlayerColor(game, 1)}
              killedPieces={killedPieces[getPlayerColor(game, 0)]}
            />
          </Grid>
        </Grid>

        {game && (
          <>
            <Slider
              defaultValue={0}
              value={positionHistory}
              onChange={handleChange}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={0}
              max={game.history.length - 1}
            />
            <Button
              startIcon={isPlay ? <PauseCircleFilledIcon /> : <PlayCircleFilledIcon />}
              variant="contained"
              disabled={game.history.length <= 1}
              color="primary"
              onClick={() => setPlay((isPlay) => !isPlay)}
            >
              {isPlay ? 'Пауза' : 'Играть'}
            </Button>
          </>
        )}
      </Grid>
      <Grid xs={6}>
        <Report game={game} />
      </Grid>
    </Grid>
  );
}
