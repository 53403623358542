import React, { useMemo, useRef, useCallback, memo } from 'react';
import MaterialTable from 'material-table';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import _ from 'lodash';
import qs from 'qs';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';

import endpoints from 'api/endpoints';
import { useAuthAPI } from 'hooks/use-auth-api';
import { errorService } from 'services/alert/services';
import { mapFilters } from 'utils/map-table-filters';
import { useStore } from 'hooks/use-store';
import { URLHelper } from 'shared/helpers/URLHelper';

const nicknameSchema = Yup.object().shape({
  nickname: Yup.string()
    .trim()
    .matches(/^[^а-яА-Я]+$/, 'Используйте только английские буквы!')
    .min(2, 'Никнейм должен быть больше 2 символов!')
    .max(10, 'Никнейм очень длинное (макс. 8 символа)!')
    .required('Введите Никнейм'),
});

const emailSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .matches(/^[^а-яА-Я]+$/, 'Используйте только английские буквы!')
    .max(64, 'Слишком длинный почтовый ящик!')
    .required('Введите почтовый ящик')
    .email('Почтовый ящик должен быть в формате name@domain.com!'),
});

const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .matches(/^[^а-яА-Я]+$/, 'Используйте только английские буквы!')
    .min(8, 'Пароль должен быть больше 8 символов!')
    .max(16, 'Пароль очень длинный (макс. 16 символа)!')
    .required('Введите пароль'),
});

function validateField(schema, data) {
  try {
    schema.validateSync(data);
  } catch (error) {
    return error.message;
  }
  return true;
}

export const securityStatuses = {
  new: 'Новый',
  verified: 'Проверенный',
  warning: 'Предупреждение',
  dangerous: 'Опасный',
};

export const mapSecurityStatus = (status) => {
  return securityStatuses[status] || '';
};

const transformFilterQueries = {};

const pageSizes = [5, 10, 20, 30];

const getRowStyle = (rowData) => {
  if (rowData.blocked) return { backgroundColor: 'pink' };
  if (
    !rowData.verificationInfo?.verified &&
    (rowData.verificationInfo.documentType ||
      rowData.verificationInfo.photoUrl ||
      rowData.verificationInfo.fullname ||
      rowData.verificationInfo?.documentId)
  )
    return {
      backgroundColor: '#4fdbc9',
    };

  if (rowData?.gameSettings?.isChatBlocked) return { backgroundColor: '#fcdf03' };

  return;
};

const UsersTable = ({
  setCurrentUser,
  setOpenCreateDialog,
  setOpenUpdateSecurityForm,
  onUserClickHandler,
  onBlockChatClickHandler,
  onRowAdd,
  onRowUpdate,
  onRowDelete,
  tableRef,
}) => {
  const request = useAuthAPI();
  const { search } = useLocation();
  const { referralPrograms, ranks } = useStore();
  // TODO: remove material-table and find a replacement for it and remove this useRef and refactor it
  const isFirstRender = useRef(true);

  const searchQueryFilters = qs.parse(search.substring(1));

  const referralProgramsById = useMemo(() => {
    return _.keyBy(referralPrograms, '_id');
  }, [referralPrograms]);

  const ranksById = useMemo(() => {
    return _.keyBy(ranks, '_id');
  }, [ranks]);

  const referralProgramLookup = useMemo(() => {
    return _.mapValues(referralProgramsById, 'owner.email');
  }, [referralProgramsById]);

  const ranksLookup = useMemo(() => {
    return _.mapValues(ranksById, 'title');
  }, [ranksById]);

  const getReferralProgramOwner = (id) => {
    return referralProgramsById[id]?.owner;
  };

  const onFilterChange = useCallback(async (query) => {
    try {
      const filters = mapFilters(query.filters, transformFilterQueries);
      const commonQuery = query.search ? { commonQuery: query.search } : {};

      const page = isFirstRender.current
        ? // @ts-ignore
          !isNaN(+searchQueryFilters.page)
          ? // @ts-ignore
            +searchQueryFilters.page
          : query.page
        : Number(query.page);

      isFirstRender.current = false;

      URLHelper.replaceSearchParams(qs.stringify({ ...filters, page, pageSize: query.pageSize }));

      const orderQuery = query.orderBy
        ? { orderBy: query.orderBy.field, orderDirection: query.orderDirection }
        : {};

      const response = await request(endpoints.users, {
        limit: query.pageSize,
        offset: page * query.pageSize,
        ...orderQuery,
        ...commonQuery,
        ...filters,
      });
      return {
        data: response.data.users,
        page,
        totalCount: response.data.totalCount,
        pageSize: query.pageSize,
      };
    } catch (e) {
      errorService.sendError(e.message);
    }
  }, []);

  const actions = useMemo(() => {
    return [
      {
        icon: 'block',
        tooltip: 'Блокировка пользователя.',
        onClick: onUserClickHandler,
      },
      {
        icon: SpeakerNotesOffIcon,
        tooltip: 'Блокировка чата.',
        onClick: onBlockChatClickHandler,
      },
    ];
  }, []);

  const onSecurityClick = (e, rowData) => {
    e.stopPropagation();
    e.preventDefault();
    setCurrentUser(rowData);
    setOpenUpdateSecurityForm(true);
  };

  const columns = useMemo(() => {
    return [
      {
        title: 'Никнейм',
        field: 'nickname',
        defaultFilter: searchQueryFilters.nickname,
        validate: (rowData) =>
          validateField(nicknameSchema, {
            nickname: rowData.nickname,
          }),

        // eslint-disable-next-line react/display-name
        render: (rowData) => (
          <Link to={{ pathname: `games/${rowData._id}`, state: rowData }}>{rowData.nickname}</Link>
        ),
      },
      {
        title: 'Почта',
        field: 'email',
        defaultFilter: searchQueryFilters.email,
        validate: (rowData) => validateField(emailSchema, { email: rowData.email }),
      },
      {
        title: 'Пароль',
        field: 'password',
        filtering: false,
        sorting: false,
        validate: (rowData) =>
          rowData.password === '***'
            ? true
            : validateField(passwordSchema, {
                password: rowData.password,
              }),
        // eslint-disable-next-line react/display-name
        render: () => <div>***</div>,
      },
      {
        title: 'Баланс',
        field: 'balance',
        type: 'numeric',
        editable: false,
        filtering: false,
        render: (rowData) => (
          <Link
            to={{
              pathname: `transactions/${rowData._id}`,
              state: rowData,
            }}
          >
            {rowData?.balance?.toFixed(2)}
          </Link>
        ),
      },
      {
        title: 'Регистрация',
        field: 'registered',
        type: 'date',
        editable: false,
        defaultFilter: searchQueryFilters.registered,
      },
      {
        title: 'Рейтинг',
        field: 'rating',
        type: 'numeric',
        editable: false,
        filtering: false,
        sorting: false,
        defaultFilter: searchQueryFilters.rating,
        render: (rowData) => (
          <Link
            to={{
              pathname: `rating-histories/${rowData._id}`,
              state: rowData,
            }}
          >
            {rowData?.rating?.toFixed(2)}
          </Link>
        ),
      },
      {
        title: 'Реферальная программа',
        field: 'referralProgramId',
        sorting: false,
        lookup: referralProgramLookup,
        defaultFilter: searchQueryFilters.referralProgramId,
        render: (rowData) => {
          const owner = getReferralProgramOwner(rowData.referralProgramId);
          if (owner) {
            return (
              <>
                {owner.nickname} <br />
                {owner.email}
              </>
            );
          }
          return rowData?.referralProgramId;
        },
      },
      {
        title: 'Ранг',
        field: 'rank',
        sorting: false,
        filtering: false,
        lookup: ranksLookup,
        render: (rowData) => {
          const owner = ranksLookup[rowData.rank];
          return owner;
        },
      },
      {
        title: 'Cтатус безопасности',
        field: 'securityStatus',
        sorting: false,
        editable: false,
        multiple: false,
        filtering: false,
        render: (rowData) => (
          <span onClick={(e) => onSecurityClick(e, rowData)} style={{ color: '#9c27b0' }}>
            {mapSecurityStatus(rowData?.securityStatus)}
          </span>
        ),
      },
    ];
  }, [referralProgramLookup, ranksLookup]);

  return (
    // @ts-ignore
    <MaterialTable
      title="Пользователи"
      key="users"
      tableRef={tableRef}
      onRowClick={(e, rowData) => {
        setCurrentUser(rowData);
        setOpenCreateDialog(true);
      }}
      localization={{
        body: {
          editTooltip: 'Редактировать',
          deleteTooltip: 'Удалить',
          editRow: {
            deleteText: 'Вы уверены что хотите удалить пользователя?',
          },
        },
        toolbar: { searchPlaceholder: 'Поиск' },
        pagination: { labelRowsSelect: 'cтрок' },
        header: {
          actions: 'Действия',
        },
      }}
      actions={actions}
      columns={columns}
      data={onFilterChange}
      options={{
        filtering: true,
        pageSizeOptions: pageSizes,
        defaultSort: 'desc',
        // @ts-ignore
        pageSize: !isNaN(+searchQueryFilters.pageSize)
          ? // @ts-ignore
            +searchQueryFilters.pageSize
          : pageSizes[0],
        search: true,
        debounceInterval: 700,

        rowStyle: getRowStyle,
      }}
      editable={{
        isEditable: () => true,
        onRowAddCancelled: () => console.log('Row adding cancelled'),
        onRowUpdateCancelled: () => console.log('Row editing cancelled'),
        onRowAdd,
        onRowUpdate,
        onRowDelete,
      }}
    />
  );
};

export default memo(UsersTable);
