import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import { Select, InputLabel, MenuItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { BeatLoader } from 'react-spinners';
import ErrorIcon from '@mui/icons-material/Error';
import { Typography } from '@mui/material';

import { errorService } from 'services/alert/services';
import { useRanks } from 'hooks/use-ranks';

import { dialogStyles } from './styles';
import { tournamentType, isType, isMode, modes } from './utils';

const availableValues = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024];

const defaultPrizes = [
  {
    amount: 0,
    rating: 0,
    place: 1,
    range: null,
  },
];

const colors = [
  {
    value: 'darkGreen',
    label: 'Dark Green',
  },
  {
    value: 'darkViolet',
    label: 'Dark Violet',
  },
  {
    value: 'darkBlue',
    label: 'Dark Blue',
  },
  {
    value: 'darkGray',
    label: 'Dark Gray',
  },
  {
    value: 'darkPurple',
    label: 'Dark Purple',
  },
];

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: +values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
};

const timeControlList = [
  {
    time: 1,
    increment: 1,
    label: '1 + 1',
  },
  {
    time: 3,
    increment: 3,
    label: '3 + 3',
  },
  {
    time: 5,
    increment: 5,
    label: '5 + 5',
  },
  {
    time: 10,
    increment: 0,
    label: '10 + 0',
  },
  {
    time: 20,
    increment: 0,
    label: '20 + 0',
  },
];

const defaultTimeControl = { time: 5, increment: 5 };

const ErrorMessage = ({ message }) => {
  return <div style={{ color: 'red', fontSize: 12 }}>{message}</div>;
};

const CreateOrUpdateTournamentModal = ({ open, handleClose, onSubmit, tournament, mode }) => {
  const styles = dialogStyles();
  const { data: ranks, isLoading, isError } = useRanks();

  const initialValues = useMemo(
    () => ({
      title: tournament?.title ?? '',
      description: tournament?.description ?? '',
      prizes: tournament?.prizes ?? defaultPrizes,
      buyIn: tournament?.buyIn ?? 0,
      maxPlayers: tournament?.maxPlayers ?? 2,
      rake: tournament?.rake ?? 0,
      ratePrize: tournament?.ratePrize ?? 0,
      gameSeries: tournament?.gameSeries ?? 1,
      timeControl: tournament?.timeControl ?? { time: 5, increment: 5 },
      balanceType: tournament?.balanceType ?? 'play',
      color: tournament?.color ?? colors[0].value,
      type: tournament?.type ?? tournamentType.REGULAR,
      fromRank: tournament?.fromRank ?? '',
      startDate: tournament?.startDate
        ? moment(tournament.startDate).format('YYYY-MM-DDTHH:mm')
        : '',
      settings: {
        isPrivate: tournament?.settings?.isPrivate ?? false,
        password: tournament?.settings?.password ?? null,
        entryRank: tournament?.settings?.entryRank ?? null,
        timeControl: tournament?.settings?.timeControl ?? defaultTimeControl,
        balanceType: tournament?.settings?.balanceType ?? 'play',
        registrationStartDate: tournament?.settings?.registrationStartDate
          ? moment(tournament?.settings?.registrationStartDate).format('YYYY-MM-DDTHH:mm')
          : null,
      },
    }),
    [tournament],
  );

  const handleAddOnePrize = (prizes, setFieldValue) => {
    const lastPrize = prizes[prizes.length - 1];
    const newPrize = {
      amount: 0,
      rating: 0,
      place: lastPrize.place === 4 || lastPrize.place == null ? null : lastPrize.place + 1,
      range:
        lastPrize.place === 4
          ? [4, 8]
          : lastPrize.place == null
          ? lastPrize.range.map((r) => r * 2)
          : null,
    };
    setFieldValue('prizes', [...prizes, newPrize]);
  };

  const handleDeleteOnePrize = (prizes, setFieldValue) => {
    const lastPrize = prizes[prizes.length - 1];
    if (lastPrize.place !== 1) {
      setFieldValue('prizes', [...prizes.slice(0, -1)]);
    } else {
      errorService.sendError(`Нельзя удалить приз за первое место`);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className={styles.dialog}
        PaperProps={{ className: styles.paper }}
      >
        <DialogTitle id="form-dialog-title">
          {isMode(mode, modes.update) ? 'Обновить' : 'Создать'} турнир
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              title: Yup.string().required('Введите название'),
              description: Yup.string().required('Введите описание'),
              buyIn: Yup.number().required('Введите Сумму buyIn'),
              rake: Yup.number().required('Введите рейк'),
              ratePrize: Yup.number().required('Введите приз победителю (рейтинг)'),
              prizes: Yup.array().of(
                Yup.object().shape({
                  amount: Yup.number('Должно быть числом').required('Введите сумму приза'),
                  place: Yup.number('Должно быть числом').nullable(true),
                  range: Yup.array().of(Yup.number('Must be number')).nullable(true),
                }),
              ),
              gameSeries: Yup.number().min(1, 'Минимум - 1').required('Обязательное поле'),
              maxPlayers: Yup.number()
                .min(2, 'Минимальное количество участников -> 2')
                .required('Введите количество игроков')
                .test(
                  'maxPlayers',
                  'Количество игроков должно быть кратно 2',
                  (value) => !!availableValues.find((el) => el === +value),
                ),
              startDate: Yup.date().required('Введите дату турнира'),
              timeControl: Yup.object().shape({
                time: Yup.number('Время должно быть числом').required('Введите время игр'),
              }),
              balanceType: Yup.string().required('Введите тип игры'),
              color: Yup.string().required('Цвет'),
              type: Yup.string().required('Введите тип турнира'),
              fromRank: Yup.string(),
              settings: Yup.object().shape({
                isPrivate: Yup.boolean(),
                password: Yup.string().nullable(),
                entryRank: Yup.string().nullable(),
                timeControl: Yup.object(),
                balanceType: Yup.string(),
                registrationStartDate: Yup.string().nullable(),
              }),
            })}
            onSubmit={(values) => onSubmit(values, initialValues)}
            validateOnChange
            render={({
              values,
              errors,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              touched,
            }) => {
              return (
                <form onSubmit={handleSubmit} className={styles.form}>
                  <FormControl component="fieldset" className={styles.formControl}>
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.title && touched.title)}
                      name="title"
                      margin="dense"
                      label="Название"
                      value={values.title}
                      placeholder="Введите название"
                    />

                    <div style={{ color: 'red', fontSize: 12 }}>
                      {touched.title ? (errors.title as string) : ''}
                    </div>

                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.description && touched.description)}
                      name="description"
                      margin="dense"
                      label="Описание"
                      value={values.description}
                      placeholder="Введите Описание"
                    />
                    <div style={{ color: 'red', fontSize: 12 }}>
                      {touched.description ? (errors.description as string) : ''}
                    </div>

                    <FormControl style={{ minWidth: 120, margin: '10px 0' }} size="small">
                      <InputLabel id="demo-select-small">Время игр</InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={values.timeControl.time}
                        label="Время"
                        name="timeControl.time"
                        onChange={(e) => {
                          const value = e.target.value;
                          const timeControl = timeControlList.find((t) => t.time === value);
                          if (!timeControl)
                            throw new Error(`Time control with time - ${value} not found`);
                          setFieldValue('timeControl', {
                            time: timeControl.time,
                            increment: timeControl.increment,
                          });
                        }}
                      >
                        {timeControlList.map((timeControl) => {
                          return (
                            <MenuItem value={timeControl.time} key={timeControl.time}>
                              {timeControl.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    <FormControl style={{ minWidth: 120, margin: '10px 0' }} size="small">
                      <InputLabel id="demo-select-small">Цвет</InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={values.color}
                        label="Цвет"
                        name="color"
                        onChange={handleChange}
                      >
                        {colors.map((color) => (
                          <MenuItem value={color.value} key={color.value}>
                            {color.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl style={{ minWidth: 120, margin: '10px 0' }} size="small">
                      <InputLabel id="demo-select-small">Тип игр</InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={values.balanceType}
                        label="Время"
                        name="balanceType"
                        onChange={handleChange}
                      >
                        <MenuItem value="play">Доллар</MenuItem>
                        <MenuItem value="coins">Dice coins</MenuItem>
                        <MenuItem value="referralBonus">Бонус</MenuItem>
                      </Select>
                    </FormControl>

                    {isMode(mode, modes.create) && (
                      <FormControl style={{ minWidth: 120, margin: '10px 0' }} size="small">
                        <InputLabel id="demo-select-small">Тип турнира</InputLabel>
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={values.type}
                          label="Тип"
                          name="type"
                          onChange={handleChange}
                        >
                          <MenuItem value={tournamentType.REGULAR}>Обычный</MenuItem>
                          <MenuItem value={tournamentType.JACKPOT}>Джек пот</MenuItem>
                        </Select>
                      </FormControl>
                    )}

                    {isType(values.type, tournamentType.JACKPOT) && (
                      <FormControl style={{ minWidth: 120, margin: '10px 0' }} size="small">
                        <InputLabel id="demo-select-small">Ранг</InputLabel>
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={values.fromRank}
                          label="Ранг"
                          name="fromRank"
                          onChange={handleChange}
                          displayEmpty
                          error={touched.fromRank ? Boolean(errors.fromRank) : false}
                        >
                          {isLoading && (
                            <div className={styles.rankIcon}>
                              <BeatLoader size={16} color="#00acc1" loading={true} />
                            </div>
                          )}
                          {isError && (
                            <div className={styles.rankIcon}>
                              <ErrorIcon className={styles.errorIcon} />
                            </div>
                          )}
                          {ranks &&
                            ranks.map((rank) => (
                              <MenuItem key={rank._id} value={rank._id}>
                                {rank.title}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}

                    {isType(values.type, tournamentType.REGULAR) && isMode(mode, modes.create) && (
                      <>
                        <TextField
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!(errors.maxPlayers && touched.maxPlayers)}
                          name="maxPlayers"
                          margin="dense"
                          label="Количество игроков"
                          value={values.maxPlayers}
                          placeholder="Введите количество"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                        />
                        <div style={{ color: 'red', fontSize: 12 }}>
                          {touched.maxPlayers ? (errors.maxPlayers as string) : ''}
                        </div>
                      </>
                    )}

                    {isType(values.type, tournamentType.REGULAR) && (
                      <>
                        <TextField
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!(errors.buyIn && touched.buyIn)}
                          name="buyIn"
                          margin="dense"
                          label="Buy in"
                          value={values.buyIn}
                          placeholder="Введите buy in"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                        />
                        <div style={{ color: 'red', fontSize: 12 }}>
                          {touched.buyIn ? (errors.buyIn as string) : ''}
                        </div>
                      </>
                    )}

                    {isType(values.type, tournamentType.REGULAR) && (
                      <>
                        <TextField
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!(errors.rake && touched.rake)}
                          name="rake"
                          margin="dense"
                          label="Рейк(%)"
                          value={values.rake}
                          placeholder="Введите рейк"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                        />

                        <div style={{ color: 'red', fontSize: 12 }}>
                          {touched.rake ? (errors.rake as string) : ''}
                        </div>
                      </>
                    )}

                    {isType(values.type, tournamentType.REGULAR) && (
                      <>
                        <TextField
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!(errors.ratePrize && touched.ratePrize)}
                          name="ratePrize"
                          margin="dense"
                          label="Приз за выигрыш (рейтинг)"
                          value={values.ratePrize}
                          placeholder="Приз победителю (очков рейтинга)"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                        />

                        <div style={{ color: 'red', fontSize: 12 }}>
                          {touched.ratePrize ? (errors.ratePrize as string) : ''}
                        </div>
                      </>
                    )}

                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.gameSeries && touched.gameSeries)}
                      name="gameSeries"
                      margin="dense"
                      label="Серия игр"
                      value={values.gameSeries}
                      placeholder="Серия игр"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />

                    <div style={{ color: 'red', fontSize: 12 }}>
                      {(errors.gameSeries as string) || ''}
                    </div>

                    {isType(values.type, tournamentType.REGULAR) && (
                      <div
                        style={{
                          border: '3px solid gray',
                          display: 'flex',
                          flexDirection: 'column',
                          margin: '15px 0',
                          padding: '10px 5px',
                        }}
                      >
                        {values.prizes.map((prize, i) => {
                          return (
                            <div
                              style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                gridColumnGap: '5px',
                              }}
                            >
                              <TextField
                                key={i}
                                onChange={handleChange}
                                name={`prizes[${i}].amount`}
                                margin="dense"
                                label={`Приз за ${
                                  prize.place || `${prize.range[0]}-${prize.range[1]}`
                                } место`}
                                value={values.prizes[i].amount}
                                placeholder="Введите приз"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                              />
                              <TextField
                                key={i}
                                onChange={handleChange}
                                name={`prizes[${i}].rating`}
                                margin="dense"
                                label={`Рейтинг за ${
                                  prize.place || `${prize.range[0]}-${prize.range[1]}`
                                } место`}
                                value={values.prizes[i].rating}
                                placeholder="Введите рейтинг"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                              />
                            </div>
                          );
                        })}
                        <div style={{ color: 'red', fontSize: 12 }}>
                          {Array.isArray(errors.prizes) &&
                            errors.prizes.filter(Boolean).map((err: Record<string, any>, i) => {
                              return <span key={i}>{err.amount || err.place}</span>;
                            })}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={() => {
                              handleAddOnePrize(values.prizes, setFieldValue);
                            }}
                          >
                            <AddIcon />
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => {
                              handleDeleteOnePrize(values.prizes, setFieldValue);
                            }}
                          >
                            <RemoveIcon />
                          </Button>
                        </div>
                      </div>
                    )}
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.startDate && touched.startDate)}
                      name="startDate"
                      type="datetime-local"
                      margin="dense"
                      value={values.startDate}
                      label="Дата начала турнира"
                      placeholder="Дата турнира"
                      InputLabelProps={{ shrink: true }}
                    />
                    <ErrorMessage message={touched.startDate ? errors.startDate : ''} />
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="settings.registrationStartDate"
                      type="datetime-local"
                      margin="dense"
                      value={values.settings.registrationStartDate}
                      label="Дата начала регистрации"
                      placeholder="Дата начала регистрации"
                      InputLabelProps={{ shrink: true }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(values.settings?.isPrivate)}
                          name="settings.isPrivate"
                          onChange={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={<span className={styles.formControlLabel}>Приватный?</span>}
                    />
                    {values.settings?.isPrivate && (
                      <>
                        <TextField
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // @ts-ignore
                          error={!!(errors.settings?.password && touched.settings?.password)}
                          name="settings.password"
                          margin="dense"
                          label="Пароль"
                          value={values.settings?.password}
                          placeholder="Введите пароль"
                        />
                        <ErrorMessage
                          // @ts-ignore
                          message={touched.settings?.password ? errors.settings?.password : ''}
                        />
                      </>
                    )}
                  </FormControl>

                  <DialogActions>
                    <Button color="secondary" variant="contained" onClick={() => handleClose()}>
                      Отмена
                    </Button>
                    <Button color="primary" variant="contained" onClick={() => handleSubmit()}>
                      {isMode(mode, modes.update) ? 'Обновить' : 'Создать'}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateOrUpdateTournamentModal;
