import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function B2({ width, height }) {
  return (
    <SVGUniqueID>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        fill='none'
        viewBox='0 0 70 70'
      >
        <g clipPath='url(#clip0_2)'>
          <path
            fill='url(#paint0_linear_2)'
            d='M10 69.5A9.56 9.56 0 01.5 60V10A9.56 9.56 0 0110 .5h50a9.56 9.56 0 019.5 9.5v50a9.56 9.56 0 01-9.5 9.5H10z'
          />
          <path
            fill='#fff'
            d='M60 1a9 9 0 019 9v50a9 9 0 01-9 9H10a9 9 0 01-9-9V10a9 9 0 019-9h50zm0-1H10A10 10 0 000 10v50a10 10 0 0010 10h50a10 10 0 0010-10V10A10 10 0 0060 0z'
          />
          <path
            fill='url(#paint1_linear_2)'
            d='M22.77 32.54c5.396 0 9.77-4.374 9.77-9.77S28.166 13 22.77 13 13 17.374 13 22.77s4.374 9.77 9.77 9.77z'
          />
          <path
            fill='url(#paint2_linear_2)'
            stroke='#0B0A09'
            d='M22.77 29.83a7.06 7.06 0 100-14.12 7.06 7.06 0 000 14.12z'
          />
          <g>
            <path
              fill='url(#paint3_linear_2)'
              d='M46.77 56.54c5.396 0 9.77-4.374 9.77-9.77S52.166 37 46.77 37 37 41.374 37 46.77s4.374 9.77 9.77 9.77z'
            />
            <path
              fill='url(#paint4_linear_2)'
              stroke='#0B0A09'
              d='M46.77 53.83a7.06 7.06 0 100-14.12 7.06 7.06 0 000 14.12z'
            />
          </g>
        </g>
        <defs>
          <linearGradient
            id='paint0_linear_2'
            x1='35'
            x2='35'
            y1='69.5'
            y2='.5'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#BCC5C7' />
            <stop offset='.65' stopColor='#E6E8E9' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_2'
            x1='22.776'
            x2='22.772'
            y1='32.544'
            y2='13.004'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#fff' />
            <stop offset='1' stopColor='#A4B0B2' />
          </linearGradient>
          <linearGradient
            id='paint2_linear_2'
            x1='15.604'
            x2='29.72'
            y1='22.772'
            y2='22.772'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#3A3A3F' />
            <stop offset='1' stopColor='#040408' />
          </linearGradient>
          <linearGradient
            id='paint3_linear_2'
            x1='46.776'
            x2='46.772'
            y1='56.544'
            y2='37.004'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#fff' />
            <stop offset='1' stopColor='#A4B0B2' />
          </linearGradient>
          <linearGradient
            id='paint4_linear_2'
            x1='39.604'
            x2='53.72'
            y1='46.772'
            y2='46.772'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#3A3A3F' />
            <stop offset='1' stopColor='#040408' />
          </linearGradient>
          <clipPath id='clip0_2'>
            <path fill='#fff' d='M0 0h70v70H0z' />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
