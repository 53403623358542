import React, { useMemo } from 'react';
import { Grid, Select, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { errorService } from 'services/alert/services';

import { variableNames, variableValueTypes } from './constant';

const useStyles = makeStyles({
  fullWidth: {
    width: '100%',
    flexDirection: 'column',
  },
  form: {
    width: 500,
    margin: '0 0',
  },
  cellItem: {
    width: '100%',
    margin: '0 0 15px',
  },
  errorText: {
    width: '100%',
    color: '#f44336',
  },
  loaderContainer: {
    width: 450,
  },
  padding: { padding: 4 },
  bold: { fontWeight: 'bolder' },
});

export const CreateOrUpdateVariableModal = ({
  open,
  onClose,
  onCreate,
  onUpdate,
  mode,
  variable,
}) => {
  const classes = useStyles();

  const onHandleSubmit = async (newValues) => {
    const values = {
      ...newValues,
    };
    if (values.type === 'string') {
      values.value = String(values.value);
    } else if (values.type === 'boolean') {
      values.value = Boolean(values.value);
    } else {
      values.value = Number(values.value);
    }
    try {
      if (mode === 'create') {
        await onCreate(values);
      } else {
        await onUpdate(values, variable);
      }
    } catch (e) {
      errorService.sendError(e.message);
    } finally {
      onClose();
    }
  };

  const initialValues = useMemo(() => {
    if (mode === 'create') {
      return {
        name: '',
        value: '',
        type: 'string',
      };
    }
    return {
      name: variable?.name,
      value: variable?.value,
      type: typeof variable?.value,
    };
  }, [variable, mode]);

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {mode === 'create' ? 'Создать' : 'Обновить'} настройку
        </DialogTitle>
        <DialogContent>
          <Grid container className={classes.fullWidth}>
            <Grid item className={classes.fullWidth}>
              <Grid container className={classes.fullWidth}>
                <Formik
                  initialValues={initialValues}
                  enableReinitialize
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required('Введите имя'),
                  })}
                  onSubmit={onHandleSubmit}
                  render={({ values, errors, handleSubmit, handleChange, handleBlur }) => {
                    return (
                      <form onSubmit={handleSubmit} className={classes.form}>
                        <Grid container direction="column" justify="center" alignItems="center">
                          <Grid item xs={12} className={classes.cellItem}>
                            <Select
                              fullWidth
                              label="Имя настройки"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={!!errors.name}
                              name="name"
                              value={values.name}
                            >
                              {Object.entries(variableNames).map(([value, label], i) => {
                                return (
                                  <MenuItem key={i} value={value}>
                                    {label}
                                  </MenuItem>
                                );
                              })}
                              <MenuItem value="">Другое имя</MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={12} className={classes.errorText}>
                            {errors.name}
                          </Grid>

                          {!variableNames[values.name] && (
                            <Grid item xs={12} className={classes.cellItem}>
                              <TextField
                                fullWidth
                                multiline
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!errors.name}
                                name="name"
                                value={values.name}
                                label="Имя"
                                placeholder="Пример: 'pokerAvailability'"
                              />
                            </Grid>
                          )}
                          <Grid item xs={12} className={classes.cellItem}>
                            <Select
                              fullWidth
                              label="Тип значения"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={!!errors.type}
                              name="type"
                              value={values.type}
                            >
                              {Object.entries(variableValueTypes).map(([value, label], i) => {
                                return (
                                  <MenuItem key={i} value={value}>
                                    {label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </Grid>
                          {values.type === 'string' && (
                            <Grid item xs={12} className={classes.cellItem}>
                              <TextField
                                fullWidth
                                multiline
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!errors.value}
                                name="value"
                                value={values.value}
                                label="Значение"
                              />
                            </Grid>
                          )}
                          {values.type === 'boolean' && (
                            <Grid item xs={12} className={classes.cellItem}>
                              <Checkbox
                                checked={Boolean(values.value)}
                                name="value"
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                                label="Значение"
                                placeholder="Значение"
                              />
                            </Grid>
                          )}
                          {values.type === 'number' && (
                            <Grid item xs={12} className={classes.cellItem}>
                              <TextField
                                fullWidth
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!errors.value}
                                name="value"
                                value={values.value}
                                label="Значение"
                                type="number"
                              />
                            </Grid>
                          )}
                          <Grid item xs={12} className={classes.errorText}>
                            {errors.type}
                          </Grid>
                        </Grid>
                        <DialogActions>
                          <Button color="primary" onClick={onClose}>
                            Закрыть
                          </Button>
                          <Button color="primary" type="submit" variant="contained">
                            {mode === 'create' ? 'Создать' : 'Обновить'}
                          </Button>
                        </DialogActions>
                      </form>
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};
