import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import { AdminService } from 'services/api/admin';
import { errorService } from 'services/alert/services';

import { dialogStyles } from './styles';

const AddAdminDialog = ({ open, onHandleClose, onSuccess }) => {
  const styles = dialogStyles();

  const handleSubmit = async (values) => {
    try {
      await AdminService.addAdmin(values.id);
      onSuccess && onSuccess();
      onHandleClose();
    } catch (e) {
      errorService.sendError(`Произошла ошибка ${e?.response?.data?.message ?? e.message}`);
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={onHandleClose} className={styles.dialog}>
        <DialogTitle id="form-dialog-title">
          Добавить пользователя в админы по ID _________
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              id: '',
            }}
            validationSchema={Yup.object().shape({
              id: Yup.string().required('Введите ID'),
            })}
            onSubmit={handleSubmit}
            validateOnChange
            render={({ values, errors, handleSubmit, handleChange, handleBlur, touched }) => {
              return (
                <form onSubmit={handleSubmit} className={styles.form}>
                  <FormControl component="fieldset" className={styles.formControl}>
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.id && touched.id)}
                      name="id"
                      margin="dense"
                      label="ID"
                      value={values.title}
                      placeholder="Введите ID"
                    />

                    <div style={{ color: 'red', fontSize: 12 }}>{touched.id ? errors.id : ''}</div>
                  </FormControl>

                  <DialogActions>
                    <Button color="primary" variant="contained" onClick={() => handleSubmit()}>
                      Добавить
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddAdminDialog;
