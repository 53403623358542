export function parser(helper) {
  var parser = {
    parseFen: function (fen) {
      var board = {}
      var fen_parts = fen.replace(/^\s*/, '').replace(/\s*$/, '').split(/\/|\s/)
      var location = ''
      var piece = ''
      var color = ''
      var part = 0
      for (var j = 8; j > 0; j--) {
        var row = fen_parts[part].replace(/\d/g, this.replaceNumberWithDashes)
        part++

        var subpart = 0
        for (var k = 1; k <= 8; k++) {
          piece = row.substr(subpart, 1)
          subpart++
          if (piece !== '-') {
            location = this.getBoardLocation(j, k)

            color = helper.getPieceColor(piece)

            board[location] = {
              color: color,
              piece: helper.letterToName(piece.toLowerCase())
            }
          }
        }
      }

      return board
    },
    diff: function (fenOld, fenNew) {
      var boardOld = parser.parseFen(fenOld)
      var boardNew = parser.parseFen(fenNew)
      var actions = {
        diapered: {},
        appeared: {},
        changed: {},
        replaced: {}
      }

      var boardPrototype = parser.getAllKeys(boardOld, boardNew)
      for (var position in boardPrototype) {
        if (!boardOld[position] && boardNew[position]) {
          actions.appeared[position] = boardNew[position]
        } else if (boardOld[position] && !boardNew[position]) {
          if (!actions.diapered[parser.pieceToString(boardOld[position])]) {
            actions.diapered[parser.pieceToString(boardOld[position])] = []
          }
          actions.diapered[parser.pieceToString(boardOld[position])].push(
            position
          )
        } else if (!parser.isEqual(boardNew[position], boardOld[position])) {
          actions.appeared[position] = boardNew[position]
          if (!actions.diapered[parser.pieceToString(boardOld[position])]) {
            actions.diapered[parser.pieceToString(boardOld[position])] = []
          }
          actions.diapered[parser.pieceToString(boardOld[position])].push(
            position
          )
        }
      }

      var moves = []
      var creates = []
      var removes = []

      for (var appeared in actions.appeared) {
        if (actions.appeared.hasOwnProperty(appeared)) {
          var newPiece = actions.appeared[appeared]
          var candidates = actions.diapered[parser.pieceToString(newPiece)]

          if (candidates && candidates.length > 0) {
            var fromField = candidates[0]
            moves.push(fromField + appeared)

            candidates.splice(0, 1)
          } else {
            creates.push({
              position: appeared,
              piece: newPiece.piece,
              color: helper.colorCodeToWord(newPiece.color)
            })
          }
        }
      }
      for (var figureCombined in actions.diapered) {
        var positions = actions.diapered[figureCombined]
        for (var k = 0; k < positions.length; k++) {
          removes.push(positions[k])
        }
      }

      return {
        moves: moves,
        creates: creates,
        removes: removes
      }
    },
    getKilledPieces: function (realHistory, currentFen) {
      var killed = {
        white: {
          pawn: 0,
          knight: 0,
          bishop: 0,
          rook: 0,
          queen: 0,
          king: 0
        },
        black: {
          pawn: 0,
          knight: 0,
          bishop: 0,
          rook: 0,
          queen: 0,
          king: 0
        }
      }
      var lastFen

      var history = realHistory.slice(0)
      history.push(currentFen)

      for (var i = 0; i < history.length; i++) {
        if (i > 0) {
          var changes = parser.diff(lastFen, history[i])

          if (changes.removes.length) {
            var lastFenPositions = parser.parseFen(lastFen)
            var currentFenPositions = parser.parseFen(history[i])
            for (var p = 0; p < changes.removes.length; p++) {
              var currentChange = changes.removes[p]
              var piece = lastFenPositions[currentChange]

              if (
                (piece.piece !== 'pawn' && currentChange[1] !== '7') ||
                (piece.piece !== 'pawn' && currentChange[1] !== '2')
              ) {
                killed[piece.color][piece.piece] =
                  killed[piece.color][piece.piece] + 1

                // else means that pawn desapears (killed or promotion)
              } else {
                // if old piece place not filled than it means that piece is killed (not promotion)
                if (currentFenPositions[currentChange] !== undefined) {
                  killed[piece.color][piece.piece] =
                    killed[piece.color][piece.piece] + 1
                }

                // don't count removed promoted pawn (do nothing)
              }
            }
          }
        }
        lastFen = history[i]
      }

      const { white, black } = killed

      return {
        white: {
          P: white.pawn,
          N: white.knight,
          B: white.bishop,
          R: white.rook,
          Q: white.queen,
          K: white.king
        },
        black: {
          P: black.pawn,
          N: black.knight,
          B: black.bishop,
          R: black.rook,
          Q: black.queen,
          K: black.king
        }
      }
    },
    isEqual: function (pieceA, pieceB) {
      if (pieceA.color === pieceB.color && pieceA.piece === pieceB.piece) {
        return true
      }

      return false
    },
    pieceToString: function (piece) {
      return piece.piece + '_' + piece.color
    },
    stringToPiece: function (string) {
      var pieces = string.split('_')

      return {
        piece: pieces[0],
        color: pieces[1]
      }
    },
    getAllKeys: function (boardOld, boardNew) {
      var board = parser.clone(boardOld)
      for (var pos in boardNew) {
        if (boardNew.hasOwnProperty(pos)) {
          if (!(pos in board)) {
            board[pos] = boardNew[pos]
          }
        }
      }

      return board
    },
    getFenData: function (fen) {
      var parts = fen.split(' ')

      var moves = 0
      var dice = [0, 0, 0]

      if (parts[4] !== '-') {
        moves = parts[4].length
        var peaces = parts[4].split('')

        for (var i = 0; i < moves; i++) {
          dice[i] = helper.letterToNumber(peaces[i])
        }
      }

      return {
        turn: parts[1],
        castling: parts[2],
        en_passant: parts[3],
        legal_pieces: parts[4],
        moves: moves,
        dice: dice,
        rolled: !(parts[4] === '-' || parts[4].length < 1),
        toss: !!(parts[1] === '-' || parts[1].length < 1)
      }
    },
    getDice: function (fen) {
      var parts = fen.split(' ')
      var figures = parts[4]
      var dice = []
      if (figures !== '-') {
        var moves = parts[4].length
        var peaces = parts[4].split('')

        for (var i = 0; i < moves; i++) {
          dice[i] = helper.letterToNumber(peaces[i])
        }
      }

      return dice
    },
    getColor: function (fen) {
      var parts = fen.split(' ')

      return parts[1]
    },
    getTime: function (fen) {
      var parts = fen.split(' ')

      return +parts[5] || Date.now()
    },
    getDiceDetail: function (fen, history) {
      var dice = parser.getDice(fen)
      var fullDiceAt = dice.length + history.length - 3

      var response = []

      var full = dice.slice(0)
      var index = 0
      if (
        dice.length !== 0 &&
        dice.length !== 3 &&
        typeof history[fullDiceAt] !== 'undefined'
      ) {
        full = parser.getDice(history[fullDiceAt])
      }

      var length = full.length
      for (var k = 0; k < length; k++) {
        var singleDice = {
          figure: full[k],
          active: true,
          status: 'active'
        }
        index = dice.indexOf(full[k])
        if (index === -1) {
          singleDice.active = false
          singleDice.status = 'inactive'
        } else {
          dice.splice(index, 1)
        }

        response.push(singleDice)
      }

      if (response.length === 0) {
        response = helper.diceArrayToObject([0, 0, 0], false)
      }

      return response
    },
    getPrevDiceDetail: function (fen, history) {
      var color = helper.inverseColor(parser.getColor(fen))
      var yourTurn = history.length - 1

      var response = helper.diceArrayToObject([0, 0, 0], false, 'old')
      var found = false
      var dice = []
      var olderFen = ''

      while (history[yourTurn] && found === false) {
        olderFen = history[yourTurn]
        dice = parser.getDice(olderFen)
        if (
          helper.colorCodeToWord(color) ===
            helper.colorCodeToWord(parser.getColor(olderFen)) &&
          dice.length === 3
        ) {
          found = true
          response = helper.diceArrayToObject(dice, false, 'old')
        }

        yourTurn--
      }

      return response
    },
    arrayToString: function (array) {
      var empty = 0
      var response = ''
      for (var j = 8; j > 0; j--) {
        for (var k = 1; k <= 8; k++) {
          var location = this.getBoardLocation(j, k)
          if (!array[location]) {
            empty++
          } else {
            if (empty !== 0) {
              response += empty
            }

            response += helper.nameToLetter(
              array[location].piece,
              array[location].color
            )

            empty = 0
          }
        }
        if (empty !== 0) {
          response += empty
          empty = 0
        }
        if (j !== 1) {
          response += '/'
        }
      }

      return response
    },
    replaceNumberWithDashes: function (str) {
      var num_spaces = parseInt(str)
      var new_str = ''
      for (var i = 0; i < num_spaces; i++) {
        new_str += '-'
      }

      return new_str
    },
    getBoardLocation: function (horizontal, vertical) {
      return helper.rowToLetter(vertical) + horizontal
    },
    clone: function (obj) {
      if (obj instanceof Object) {
        var copy = {}
        for (var attr in obj) {
          if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr]
        }
        return copy
      }

      throw obj
    },
    diceMessagesFromHistory(
      history = [],
      leftColor = 'white',
      notifications = [],
      finished = false
    ) {
      let moves = []
      const [firstFen, ...fens] = history
      const prevHistory = [firstFen]

      fens.reduce((previous, current) => {
        moves = parser.pushToDiceMessages(moves, leftColor, current, previous)

        const a = parser.getDiceDetail(current, prevHistory)

        prevHistory.push(current)

        return current || a
      }, firstFen)

      if (finished && moves[moves.length - 1]) {
        const lastMove = moves[moves.length - 1]

        lastMove.pieces.forEach((piece) => (piece.moved = true))
      }

      return parser.mergeChatHistoryMessages(moves, notifications)
    },
    pushToDiceMessages(
      messages = [],
      leftColor = 'white',
      currentFen,
      previousFen
    ) {
      let [lastMove, ...rest] = messages.reverse()
      let fenForDiff = previousFen
      const moves = rest.reverse()
      const color = helper.colorCodeToWord(parser.getColor(currentFen))
      const dices = parser.getDice(currentFen)
      const diff = parser.diff(fenForDiff, currentFen)
      const parsed = parser.parseFen(fenForDiff)

      if (dices.length === 3) {
        if (lastMove) {
          moves.push({
            ...lastMove,
            pieces: lastMove.pieces.map((move) => ({
              ...move,
              moved: true
            }))
          })
        }

        lastMove = {
          color,
          pieces: dices.map((dice) => ({
            dice,
            type: helper.numberToLetter(dice),
            moved: false,
            move: ''
          })),
          time: parser.getTime(currentFen),
          position: color === leftColor ? 'ltr' : 'rtl'
        }
      } else if (lastMove && parsed) {
        const [firstMove] = diff.moves

        if (firstMove) {
          const humanizedMove = `${firstMove}`.replace(
            /^(.{2})(.{2})$/,
            '$1 - $2'
          )
          const [from, to] = `${humanizedMove}`.split(' - ')
          const currentDice = helper.letterToNumber(
            helper.nameToLetter(parsed[from].piece, color)
          )

          lastMove.pieces.find((move) => {
            if (!move.moved && move.dice === currentDice) {
              move.moved = true

              if (diff.moves.length) {
                move.move = `${from} - ${to}`
              }

              return true
            }
          })
        }

        fenForDiff = currentFen
      }

      if (lastMove) {
        moves.push(lastMove)
      }

      return moves
    },
    mergeChatHistoryMessages(messages = [], newMessages = []) {
      return messages.concat(...newMessages).sort((a, b) => a.time - b.time)
    }
  }

  return parser
}
