import { makeStyles } from '@material-ui/core/styles';

export const dialogStyles = makeStyles(() => ({
  dialog: {
    zIndex: 10000,
  },
  paper: {
    maxWidth: 500,
    width: '100%',
  },
  form: {
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
  selectFormControl: {
    margin: '15px 0',
  },
  checkboxFormControl: {
    margin: '10px 0',
  },
  actionsWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridRowGap: '20px',
    margin: '10px 0',
  },
  errorText: {
    color: 'red',
    fontSize: 12,
  },
  actionButtons: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  actionButton: {
    justifySelf: 'center',
  },
  formControlLabel: {
    color: 'rgba(0, 0, 0, 0.87)!important',
    fontWeight: '300',
  },
}));
