import endpoints from 'api/endpoints';
import { api } from 'api/rest';

export class BannerService {
  static async getBanners() {
    const response = await api.get(endpoints.banners);
    return response;
  }
  static async createBanner(data) {
    const response = await api.post(endpoints.banners, data);
    return response;
  }
  static async updateBannerById(id, data) {
    const response = await api.put(`${endpoints.banners}/${id}`, data);
    return response;
  }
  static async deleteBannerById(id) {
    const response = await api.delete(`${endpoints.banners}/${id}`);
    return response;
  }
}
