import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Select, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import { BeatLoader } from 'react-spinners';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { UserService } from 'services/api/user';

import { securityStatuses } from '../UsersTable/index';

const useStyles = makeStyles({
  fullWidth: {
    width: '100%',
    flexDirection: 'column',
  },
  form: {
    width: 500,
    margin: '0 0',
  },
  cellItem: {
    width: '100%',
    margin: '0 0 15px',
  },
  loaderContainer: {
    width: 450,
  },
  padding: { padding: 4 },
  bold: { fontWeight: 'bolder' },
});

export const UpdateUserSecurityForm = ({ profile, ...props }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = (data) => {
    props.onHandleClose(data);
    setOpen(false);
  };

  if (!profile) return null;

  return (
    <div>
      <Dialog open={open} onClose={() => handleClose(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Статус безопасности пользователя</DialogTitle>
        <DialogContent>
          <Grid container className={classes.fullWidth}>
            <Grid item className={classes.fullWidth}>
              <Grid container className={classes.fullWidth}>
                <Formik
                  initialValues={{ status: profile?.securityStatus || 'new', comment: '' }}
                  enableReinitialize
                  validationSchema={Yup.object().shape({})}
                  onSubmit={async ({ status, comment }, { setSubmitting }) => {
                    const comments = [...(profile?.comments ?? [])];
                    if (comment.trim()) {
                      comments.push(comment);
                    }
                    await UserService.update(profile._id, { securityStatus: status, comments });
                    setSubmitting(false);
                    props.onUpdate();
                    handleClose();
                  }}
                  render={({ values, errors, handleSubmit, handleChange, isSubmitting }) => {
                    return (
                      <form onSubmit={handleSubmit} className={classes.form}>
                        <Grid container direction="column" justify="center" alignItems="center">
                          <Grid item xs={12} className={classes.cellItem}>
                            {errors.comment || errors.status}
                          </Grid>

                          <Grid item xs={12} className={classes.cellItem}>
                            <Select
                              fullWidth
                              label="Статус"
                              onChange={handleChange}
                              error={!!errors.status}
                              name="status"
                              value={values.status}
                            >
                              {Object.entries(securityStatuses).map(([status, label]) => {
                                return (
                                  <MenuItem key={status} value={status}>
                                    {label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </Grid>

                          <Grid item xs={12} className={classes.cellItem}>
                            <TextField
                              fullWidth
                              multiline
                              onChange={handleChange}
                              error={!!errors.comment}
                              name="comment"
                              value={values.comment}
                              label="Новый комментарий"
                            />
                          </Grid>

                          <Grid item xs={12} style={{ paddingTop: 20, width: 200 }}>
                            <Button
                              fullWidth
                              variant="contained"
                              color="primary"
                              onClick={handleSubmit}
                            >
                              {isSubmitting ? (
                                <BeatLoader size={10} color="white" loading={true} />
                              ) : (
                                'Отправить'
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => handleClose(false)}>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

UpdateUserSecurityForm.propTypes = {
  profile: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onHandleClose: PropTypes.func.isRequired,
};
