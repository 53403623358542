import { makeAutoObservable } from 'mobx';

import { CountryEntity, ICountry } from 'domain/country';
import { CountryService } from 'services/api/country';

export class CountryStore {
  countries: ICountry[];

  constructor() {
    makeAutoObservable(this);
  }

  async getCountries() {
    try {
      const response = await CountryService.findAll();
      const countries = response.data.countries.map(
        (country) => new CountryEntity(country.id, country.code, country.name),
      );
      this.countries = countries;
    } catch (e) {}
  }
}

export default CountryStore;
