import React from "react";
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import { errorService, infoService } from 'services/alert/services';
import { ChatService } from 'services/api/chat';
import { dialogStyles } from './styles';

const CreateChatModal = ({ open, onHandleClose, onSuccess }) => {
  const styles = dialogStyles();

  const handleSubmit = async (values) => {
    try {
      const {data} = await ChatService.createSupportChat(values.id);
      infoService.sendInfo('Чат успешно создан');
      onSuccess(data.id);
    } catch (e) {
      errorService.sendError(e?.response?.data?.message ?? e.message);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onHandleClose}
        className={styles.dialog}
        PaperProps={{ className: styles.paper }}
      >
        <DialogTitle id="form-dialog-title">Создать чат</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              id: '',
            }}
            validationSchema={Yup.object().shape({
              id: Yup.string().required('Введите ID'),
            })}
            onSubmit={handleSubmit}
            validateOnChange
            render={({ values, errors, handleSubmit, handleChange, handleBlur, touched }) => {
              return (
                <form onSubmit={handleSubmit} className={styles.form}>
                  <FormControl component="fieldset" className={styles.formControl}>
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.id && touched.id)}
                      name="id"
                      margin="dense"
                      label="ID"
                      value={values.title}
                      placeholder="Введите ID пользователя"
                    />

                    <div style={{ color: 'red', fontSize: 12 }}>{touched.id ? errors.id : ''}</div>
                  </FormControl>

                  <DialogActions>
                    <Button color="primary" variant="contained" onClick={() => handleSubmit()}>
                      Создать
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateChatModal;
