import React, { useEffect, useState, useMemo } from 'react';
import {
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_FullScreenToggleButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
} from 'material-react-table';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CreateOrUpdateStickerPackModal from './create-or-update';
import { useAuthAPI } from 'hooks/use-auth-api';

import endpoints from 'api/endpoints';

import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

import { errorService } from 'services/alert/services';
import { StickerPackService } from 'services/api/sticker-pack';

const styles = {
  increaseBalance: {
    color: '#2eb92e',
  },
  decreaseBalance: {
    color: '#e01919',
  },
  takingReservedBalance: {
    color: '#707070',
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  balance: {
    marginRight: '10px',
    fontWeight: 'bold',
  },
};

const useStyles = makeStyles(styles);

const StickerPacks = () => {
  const [openCreateOrUpdateDialog, setOpenCreateOrUpdateDialog] = useState(false);

  const classes = useStyles();

  const request = useAuthAPI();

  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStickerPack, setSelectedStickerPack] = useState(null);

  const columns = useMemo(
    () => [
      {
        header: 'ID',
        accessorKey: '_id',
        Cell: ({ cell }) => {
          return cell.getValue();
        },
        enableColumnFilterModes: false,
        enableSorting: false,
      },
      {
        header: 'Имя',
        accessorKey: 'name',
        Cell: ({ cell }) => {
          return cell.getValue();
        },
        enableColumnFilterModes: false,
        enableSorting: false,
      },
      {
        header: 'Количество стикеров',
        accessorKey: 'stickers',
        Cell: ({ cell }) => {
          return cell.getValue()?.length;
        },
        enableColumnFilterModes: false,
        enableSorting: false,
      },
    ],
    [],
  );

  const getStickerPacks = async () => {
    try {
      setIsLoading(true);

      const { data: remoteData } = await request(endpoints.stickerPacks);
      setData(remoteData);
      setIsError(false);
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStickerPacks();
  }, []);

  const onOpenUpdateRowModal = (row) => {
    setSelectedStickerPack(row);
    setOpenCreateOrUpdateDialog(true);
  };

  const onCreate = async (data) => {
    try {
      await StickerPackService.create(data);
      getStickerPacks();
    } catch (e) {
      errorService.sendError(e.message);
    }
  };
  const onUpdate = async (id, data) => {
    try {
      await StickerPackService.update(id, data);
      getStickerPacks();
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  const onDelete = async (row) => {
    if (!window.confirm(`Вы уверены что хотите удалить стикер пак ${row.name}?`)) {
      return;
    }
    try {
      await StickerPackService.delete(row._id);
      getStickerPacks();
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CreateOrUpdateStickerPackModal
              open={openCreateOrUpdateDialog}
              handleClose={() => {
                setOpenCreateOrUpdateDialog(false);
              }}
              mode={selectedStickerPack ? 'update' : 'create'}
              stickerPack={selectedStickerPack}
              onCreate={onCreate}
              onUpdate={onUpdate}
            />
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Управление стикерами</p>
            </CardHeader>
            <CardBody>
              <MaterialReactTable
                columns={columns}
                data={data}
                enableColumnFilterModes
                enableColumnOrdering
                enableGrouping
                enableRowActions
                initialState={{ showColumnFilters: true }}
                positionToolbarAlertBanner="bottom"
                muiTableBodyRowProps={() => ({
                  style: {
                    cursor: 'pointer',
                  },
                })}
                renderRowActions={({ row }) => (
                  <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                    <IconButton
                      onClick={() => {
                        onOpenUpdateRowModal(row.original);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        onDelete(row.original);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
                renderTopToolbar={({ table }) => {
                  return (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Box>
                        <IconButton
                          onClick={() => {
                            setSelectedStickerPack(null);
                            setOpenCreateOrUpdateDialog(true);
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                        <MRT_ShowHideColumnsButton table={table} />
                        <MRT_FullScreenToggleButton table={table} />
                        <MRT_ToggleDensePaddingButton table={table} />
                        <MRT_ToggleFiltersButton table={table} />
                      </Box>
                    </Box>
                  );
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default StickerPacks;
