export const mapFilters = (filters, transformFilterQueries) => {
  return filters.reduce((acc, el) => {
    if (transformFilterQueries[el.column.field]) {
      acc[el.column.field] = transformFilterQueries[el.column.field](
        el.column.tableData.filterValue.trim(),
      );
      return acc;
    }
    acc[el.column.field] = el.column.tableData.filterValue;
    if (acc[el.column.field].toString().toLowerCase() === 'free') {
      acc[el.column.field] = 'coins';
    } else if (acc[el.column.field].toString().toLowerCase() === 'cash') {
      acc[el.column.field] = 'play';
    }
    return acc;
  }, {});
};
