import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function B3({ width, height }) {
  return (
    <SVGUniqueID>
      <svg
        id='b-03_svg__Layer_1'
        x={0}
        y={0}
        viewBox='0 0 70 70'
        xmlSpace='preserve'
        width={width}
        height={height}
      >
        <style>{'.b-03_svg__st9{fill:#d4d1b4}'}</style>
        <linearGradient
          id='b-03_svg__SVGID_1_'
          gradientUnits='userSpaceOnUse'
          x1={35}
          y1={69.5}
          x2={35}
          y2={0.5}
        >
          <stop offset={0} stopColor='#bcc5c7' />
          <stop offset={0.65} stopColor='#e6e8e9' />
        </linearGradient>
        <path
          d='M10 69.5C4.8 69.5.5 65.2.5 60V10C.5 4.8 4.8.5 10 .5h50c5.2 0 9.5 4.3 9.5 9.5v50c0 5.2-4.3 9.5-9.5 9.5H10z'
          fill='url(#b-03_svg__SVGID_1_)'
        />
        <path
          d='M60 1c5 0 9 4 9 9v50c0 5-4 9-9 9H10c-5 0-9-4-9-9V10c0-5 4-9 9-9h50m0-1H10C4.5 0 0 4.5 0 10v50c0 5.5 4.5 10 10 10h50c5.5 0 10-4.5 10-10V10c0-5.5-4.5-10-10-10z'
          fill='#fff'
        />
        <g>
          <linearGradient
            id='b-03_svg__SVGID_2_'
            gradientUnits='userSpaceOnUse'
            x1={9.352}
            y1={35.726}
            x2={60.648}
            y2={35.726}
          >
            <stop offset={0} stopColor='#3a3a3f' />
            <stop offset={1} stopColor='#040408' />
          </linearGradient>
          <path
            d='M56.6 17.5c-2.2 0-4 1.8-4 4 0 1.6.9 2.9 2.2 3.6l-8.3 11.2-.5-14.9c2.2-.1 3.9-1.9 3.9-4 0-2.2-1.8-4-4-4s-4 1.8-4 4c0 1.7 1.1 3.2 2.6 3.8L39 35.7l-3.1-15.6c1.8-.4 3.1-2 3.1-3.9 0-2.2-1.8-4-4-4s-4 1.8-4 4c0 1.9 1.3 3.5 3.1 3.9L31 35.7l-5.3-14.4c1.5-.6 2.6-2.1 2.6-3.8 0-2.2-1.8-4-4-4s-4 1.8-4 4 1.7 4 3.9 4l-.5 14.9-8.3-11.2c1.3-.7 2.2-2 2.2-3.6 0-2.2-1.8-4-4-4s-4 1.8-4 4 1.8 4 4 4c.4 0 .7-.1 1-.1.5 2.6 1.4 7.4 2.3 13 1.3 8.1 2.7 8.1 2.7 8.1l.7 4.7-2.5 5c-.1.1-.2.3-.2.4 0 1.5 7.9 2.7 17.5 2.7s17.5-1.2 17.5-2.7c0-.1-.1-.3-.2-.4l-2.5-5 .7-4.7s1.3 0 2.7-8.1c.9-5.5 1.8-10.4 2.3-13 .3.1.7.1 1 .1 2.2 0 4-1.8 4-4s-1.8-4.1-4-4.1z'
            fill='url(#b-03_svg__SVGID_2_)'
          />
          <linearGradient
            id='b-03_svg__SVGID_3_'
            gradientUnits='userSpaceOnUse'
            x1={35}
            y1={60.849}
            x2={35}
            y2={10.603}
          >
            <stop offset={0.001} stopColor='#fff' />
            <stop offset={1} stopColor='#a4b0b2' />
          </linearGradient>
          <path
            d='M35 12.1c2.2 0 4 1.8 4 4 0 1.9-1.3 3.5-3.1 3.9L39 35.7l5.3-14.4c-1.5-.6-2.6-2.1-2.6-3.8 0-2.2 1.8-4 4-4s4 1.8 4 4-1.7 4-3.9 4l.5 14.9 8.3-11.2c-1.3-.7-2.2-2-2.2-3.6 0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4c-.4 0-.7-.1-1-.1-.5 2.6-1.4 7.4-2.3 13-1.3 8.1-2.7 8.1-2.7 8.1l-.7 4.7 2.5 5c.1.1.2.3.2.4 0 1.5-7.9 2.7-17.5 2.7s-17.5-1.2-17.5-2.7c0-.1.1-.3.2-.4l2.5-5-.7-4.7s-1.3 0-2.7-8.1c-.9-5.5-1.8-10.4-2.3-13-.3.1-.7.1-1 .1-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4c0 1.6-.9 2.9-2.2 3.6l8.3 11.2.5-14.9c-2.2-.1-3.9-1.9-3.9-4 0-2.2 1.8-4 4-4s4 1.8 4 4c0 1.7-1.1 3.2-2.6 3.8L31 35.7l3.1-15.6c-1.8-.4-3.1-2-3.1-3.9 0-2.3 1.8-4.1 4-4.1m0-1.5c-2.9 0-5.3 2.3-5.5 5.1-.7-2.2-2.8-3.8-5.3-3.8-3.1 0-5.5 2.5-5.5 5.5 0 2.5 1.6 4.6 3.9 5.3l-.4 9.3-4.8-6.6c1-1 1.6-2.4 1.6-3.9 0-3.1-2.5-5.5-5.5-5.5S8 18.5 8 21.5s2.4 5.5 5.4 5.5c.5 2.7 1.3 6.9 2.1 11.6.8 4.7 1.7 7.5 2.8 8.7l.5 3.6-2.2 4.4c-.6.6-.6 1-.6 1.3 0 1.7 1.8 2.7 6.4 3.4 3.4.5 7.9.8 12.6.8s9.2-.3 12.6-.8c4.6-.7 6.4-1.7 6.4-3.4 0-.3-.1-.8-.5-1.3L51.4 51l.5-3.6c1.1-1.2 2-3.9 2.8-8.7.8-4.7 1.6-8.9 2.1-11.6 3-.1 5.4-2.5 5.4-5.5 0-3.1-2.5-5.5-5.5-5.5S51 18.5 51 21.6c0 1.5.6 2.9 1.6 3.9L47.8 32l-.3-9.3c2.3-.7 3.9-2.8 3.9-5.3 0-3.1-2.5-5.5-5.5-5.5-2.4 0-4.5 1.6-5.3 3.8-.3-2.8-2.7-5.1-5.6-5.1zM37.6 21c1.2-.7 2.2-1.8 2.6-3.1.1 1.6 1 3.1 2.3 4.1l-3.1 8.3-1.8-9.3zm-10.1 1c1.3-.9 2.1-2.4 2.3-4.1.4 1.3 1.4 2.4 2.6 3.1l-1.8 9.2-3.1-8.2z'
            fill='url(#b-03_svg__SVGID_3_)'
          />
          <path
            className='b-03_svg__st9'
            d='M50.8 56.1c-.1 0-.3-.1-.4-.1-4.5-1.3-9.6-2-15-2-5.7 0-11.1.8-15.7 2.2-.2 0-.3.1-.5.2l1-2.1c.1 0 .3-.1.4-.1 4.5-1.2 9.6-1.9 14.7-1.9 4.9 0 9.6.6 14 1.7.1 0 .3.1.4.1l1.1 2zM48.7 49.5l-.2 1.5v.1c-.1 0-.2 0-.3-.1-4-.8-8.3-1.2-12.8-1.2-4.8 0-9.3.5-13.5 1.4-.1 0-.2 0-.3.1V51l-.2-1.4c.1 0 .2 0 .3-.1 4.3-.9 8.9-1.4 13.7-1.4 4.5 0 8.9.4 13.1 1.2 0 .2.1.2.2.2zM49.1 45.5c.1 0 .2 0 .2.1l-.1.7-.1.9c-.1 0-.2 0-.2-.1-4.2-.9-8.7-1.4-13.5-1.4-5 0-9.8.5-14.1 1.5-.1 0-.2 0-.2.1l-.2-1.1-.1-.6c.1 0 .2 0 .2-.1 4.5-1 9.4-1.5 14.4-1.5 4.7.2 9.3.6 13.7 1.5z'
          />
        </g>
      </svg>
    </SVGUniqueID>
  )
}
