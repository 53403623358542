import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserProfile } from 'components/UserProfile';
import { Grid } from '@material-ui/core';

import { errorService } from 'services/alert/services';
import { UserService } from 'services/api/user';

export default function Game() {
  const params = useParams();

  const [userProfile, setUserProfile] = useState();

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    try {
      const { data } = await UserService.getUserProfile(params.userId);
      setUserProfile(data);
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  if (!userProfile) return [];

  return (
    <Grid
      container
      direction="columns"
      justify="center"
      alignItems="center"
      style={{ backgroundColor: 'white', padding: 40 }}
    >
      <Grid xs={12}>
        <UserProfile profile={userProfile} onVerificationUpdate={getUserProfile} />
      </Grid>
    </Grid>
  );
}
