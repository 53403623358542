import React, { memo } from "react";

export const SVGDefs = memo(
  ({
    wPid,
    wRid,
    wNid,
    wBid,
    wQid,
    wKid,
    bPid,
    bRid,
    bNid,
    bBid,
    bQid,
    bKid
  }) => {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: 1,
          height: 1,
          position: "fixed"
        }}
      >
        <defs>
          <linearGradient
            id={wPid}
            x1="9.883"
            y1="22.586"
            x2="30.117"
            y2="22.586"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F7F5D2" />
            <stop offset="1" stopColor="#D7D4B7" />
          </linearGradient>

          <linearGradient
            id={wRid}
            x1="7.669"
            y1="21.648"
            x2="32.331"
            y2="21.648"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F7F5D2" />
            <stop offset="1" stopColor="#D7D4B7" />
          </linearGradient>

          <linearGradient
            id={wNid}
            x1="3.193"
            y1="20.455"
            x2="35.248"
            y2="20.455"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F7F5D2" />
            <stop offset="1" stopColor="#D7D4B7" />
          </linearGradient>

          <linearGradient
            id={wBid}
            x1="4.269"
            y1="20.372"
            x2="35.731"
            y2="20.372"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F7F5D2" />
            <stop offset="1" stopColor="#D7D4B7" />
          </linearGradient>

          <linearGradient
            id={wQid}
            x1="2.779"
            y1="20.255"
            x2="37.221"
            y2="20.255"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F7F5D2" />
            <stop offset="1" stopColor="#D7D4B7" />
          </linearGradient>

          <linearGradient
            id={wKid}
            x1="3.71"
            y1="20.255"
            x2="36.29"
            y2="20.255"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F7F5D2" />
            <stop offset="1" stopColor="#D7D4B7" />
          </linearGradient>

          <linearGradient
            id={bPid}
            x1="9.552"
            y1="22.607"
            x2="30.448"
            y2="22.607"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3A3A3F" />
            <stop offset="1" stopColor="#040408" />
          </linearGradient>

          <linearGradient
            id={bRid}
            x1="7.2"
            y1="21.641"
            x2="32.8"
            y2="21.641"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3A3A3F" />
            <stop offset="1" stopColor="#040408" />
          </linearGradient>

          <linearGradient
            id={bNid}
            x1="3.579"
            y1="20.448"
            x2="36.421"
            y2="20.448"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3A3A3F" />
            <stop offset="1" stopColor="#040408" />
          </linearGradient>

          <linearGradient
            id={bBid}
            x1="3.71"
            y1="20.476"
            x2="36.29"
            y2="20.476"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3A3A3F" />
            <stop offset="1" stopColor="#040408" />
          </linearGradient>

          <linearGradient
            id={bQid}
            x1="2.31"
            y1="20.241"
            x2="37.69"
            y2="20.241"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3A3A3F" />
            <stop offset="1" stopColor="#040408" />
          </linearGradient>

          <linearGradient
            id={bKid}
            x1="3.241"
            y1="20.241"
            x2="36.759"
            y2="20.241"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3A3A3F" />
            <stop offset="1" stopColor="#040408" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
);
