export class AppConfig {
  env;

  // @ts-ignore
  #urls = {
    development: {
      // Админка
      client: null,
      server: null,

      // Главный фронт/сервер
      mainClient: null,
      mainServer: null,
    },
    production: {
      // Админка
      client: null,
      server: null,

      // Главный фронт/сервер
      mainClient: null,
      mainServer: null,
    },
  };

  constructor(developmentConfig, productionConfig) {
    this.#urls.development = developmentConfig;
    this.#urls.production = productionConfig;

    this.env = process.env.REACT_APP_CLIENT_NODE_ENV ?? 'prodclient';
  }

  isDevClient() {
    return this.env === 'devclient';
  }

  get urls() {
    const env = this.env === 'devclient' ? 'development' : 'production';
    return this.#urls[env];
  }
}

export type ColorKeysType = keyof typeof appColors;
export type ColorValuesType = (typeof appColors)[ColorKeysType];

export const appColors = {
  profitColor: '#2eb92e',
  lossColor: '#e01919',
};
