import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    margin: '10px 0',
    '&.center': {
      justifyContent: 'center',
    },
    '&.ltr': {
      justifyContent: 'left',
    },
    '&.rtl': {
      justifyContent: 'right',
    },
  },
  contentWrapper: {
    maxWidth: 420,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '&.center': {
      alignItems: 'center',
    },
    '&.ltr': {
      alignItems: 'flex-start',
    },
    '&.rtl': {
      alignItems: 'flex-end',
    },
  },
  createdDate: {
    display: 'block',
    marginTop: 4,
    fontSize: 12,
    color: '#797c8c!important',
    fontWeight: '600',
  },
  content: {
    width: '100%',
    background: '#fff',
    padding: '8px 14px',
    boxShadow: '0 2px 4px rgb(15 34 58 / 12%)',
    borderRadius: '3px',
    '&.rtl': {
      background: 'rgb(78,172,109, .26)',
    },
    '&.center': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  text: {
    fontSize: 15,
    fontWeight: 400,
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  },

  imageBox: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0',
    borderRadius: '15px',
    overflow: 'hidden',
    cursor: 'pointer',
    position: 'relative',
    width: '420px',
    maxWidth: '100%',
    height: 150,
    '@media screen and (max-width: 680px)': {
      height: 110,
      width: '100%',
    },
    '@media screen and (max-width: 420px)': {
      height: 110,
    },
  },
  imageShadow: {
    width: '100%',
    height: '100%',

    display: 'grid',
    placeItems: 'center',

    position: 'absolute',
    left: 0,
    top: 0,
    background: 'rgb(0, 0, 0, 0.4)',
    opacity: 0,
    transition: '0.3s ease',

    '&:hover': {
      opacity: 1,
    },
  },
  openFullScreenIcon: {
    fontSize: 20,
    color: '#fff',
    userSelect: 'none',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fill: 'currentColor',
  },
  imageItem: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});
