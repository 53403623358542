import { useState, useEffect } from "react";
import { errorService, infoService, warnService } from "./services";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";

function AlertService() {
  const [subscription, setSubscription] = useState();
  const [subscriptionInfo, setSubscriptionInfo] = useState();
  const [subscriptionWarn, setSubscriptionWarn] = useState();


  useEffect(() => {
    setSubscription(
      errorService.getError().subscribe(async error => {
        NotificationManager.error(error);
      })
    );

    setSubscriptionInfo(
      infoService.getInfo().subscribe(async info => {
        NotificationManager.success(info);
      })
    );

    setSubscriptionWarn(
      warnService.getWarn().subscribe(async warn => {
        NotificationManager.warning(warn);
      })
    );

    return function cleanup() {
      if (subscription) subscription.unsubscribe();
      if (subscriptionInfo) subscriptionInfo.unsubscribe();
      if (subscriptionWarn) subscriptionWarn.unsubscribe();

    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [];
}

export default AlertService;
