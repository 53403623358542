import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function W6({ width, height }) {
  return (
    <SVGUniqueID>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        fill='none'
        viewBox='0 0 70 70'
      >
        <g clipPath='url(#clip0_13)'>
          <path
            fill='url(#paint0_linear_13)'
            d='M9.638 70A9.699 9.699 0 010 60.362V9.638A9.699 9.699 0 019.638 0h50.724A9.698 9.698 0 0170 9.638v50.724A9.698 9.698 0 0160.362 70H9.638z'
          />
          <path
            fill='#fff'
            d='M60 1a9 9 0 019 9v50a9 9 0 01-9 9H10a9 9 0 01-9-9V10a9 9 0 019-9h50zm0-1H10A10 10 0 000 10v50a10 10 0 0010 10h50a10 10 0 0010-10V10A10 10 0 0060 0z'
          />
          <path
            fill='url(#paint1_linear_13)'
            d='M17.77 44.54c5.396 0 9.77-4.374 9.77-9.77S23.166 25 17.77 25 8 29.374 8 34.77s4.374 9.77 9.77 9.77z'
          />
          <path
            fill='url(#paint2_linear_13)'
            stroke='#0B0A09'
            d='M17.77 41.83a7.06 7.06 0 100-14.12 7.06 7.06 0 000 14.12z'
          />
          <path
            fill='url(#paint3_linear_13)'
            d='M52.77 44.54c5.396 0 9.77-4.374 9.77-9.77S58.166 25 52.77 25 43 29.374 43 34.77s4.374 9.77 9.77 9.77z'
          />
          <path
            fill='url(#paint4_linear_13)'
            stroke='#0B0A09'
            d='M52.77 41.83a7.06 7.06 0 100-14.12 7.06 7.06 0 000 14.12z'
          />
          <path
            fill='url(#paint5_linear_13)'
            d='M17.77 65.54c5.396 0 9.77-4.374 9.77-9.77S23.166 46 17.77 46 8 50.374 8 55.77s4.374 9.77 9.77 9.77z'
          />
          <path
            fill='url(#paint6_linear_13)'
            stroke='#0B0A09'
            d='M17.77 62.83a7.06 7.06 0 100-14.12 7.06 7.06 0 000 14.12z'
          />
          <g>
            <path
              fill='url(#paint7_linear_13)'
              d='M52.77 65.54c5.396 0 9.77-4.374 9.77-9.77S58.166 46 52.77 46 43 50.374 43 55.77s4.374 9.77 9.77 9.77z'
            />
            <path
              fill='url(#paint8_linear_13)'
              stroke='#0B0A09'
              d='M52.77 62.83a7.06 7.06 0 100-14.12 7.06 7.06 0 000 14.12z'
            />
          </g>
          <g>
            <path
              fill='url(#paint9_linear_13)'
              d='M17.77 23.54c5.396 0 9.77-4.374 9.77-9.77S23.166 4 17.77 4 8 8.374 8 13.77s4.374 9.77 9.77 9.77z'
            />
            <path
              fill='url(#paint10_linear_13)'
              stroke='#0B0A09'
              d='M17.77 20.83a7.06 7.06 0 100-14.12 7.06 7.06 0 000 14.12z'
            />
          </g>
          <g>
            <path
              fill='url(#paint11_linear_13)'
              d='M52.77 23.54c5.396 0 9.77-4.374 9.77-9.77S58.166 4 52.77 4 43 8.374 43 13.77s4.374 9.77 9.77 9.77z'
            />
            <path
              fill='url(#paint12_linear_13)'
              stroke='#0B0A09'
              d='M52.77 20.83a7.06 7.06 0 100-14.12 7.06 7.06 0 000 14.12z'
            />
          </g>
        </g>
        <defs>
          <linearGradient
            id='paint0_linear_13'
            x1='35'
            x2='35'
            y1='70'
            y2='0'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#BCC5C7' />
            <stop offset='.65' stopColor='#E6E8E9' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_13'
            x1='17.776'
            x2='17.772'
            y1='44.544'
            y2='25.004'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#fff' />
            <stop offset='1' stopColor='#A4B0B2' />
          </linearGradient>
          <linearGradient
            id='paint2_linear_13'
            x1='17.77'
            x2='17.77'
            y1='28.21'
            y2='41.33'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#F7F3CE' />
            <stop offset='1' stopColor='#E0DCBC' />
          </linearGradient>
          <linearGradient
            id='paint3_linear_13'
            x1='52.776'
            x2='52.772'
            y1='44.544'
            y2='25.004'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#fff' />
            <stop offset='1' stopColor='#A4B0B2' />
          </linearGradient>
          <linearGradient
            id='paint4_linear_13'
            x1='52.77'
            x2='52.77'
            y1='28.21'
            y2='41.33'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#F7F3CE' />
            <stop offset='1' stopColor='#E0DCBC' />
          </linearGradient>
          <linearGradient
            id='paint5_linear_13'
            x1='17.776'
            x2='17.772'
            y1='65.544'
            y2='46.004'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#fff' />
            <stop offset='1' stopColor='#A4B0B2' />
          </linearGradient>
          <linearGradient
            id='paint6_linear_13'
            x1='17.77'
            x2='17.77'
            y1='49.21'
            y2='62.33'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#F7F3CE' />
            <stop offset='1' stopColor='#E0DCBC' />
          </linearGradient>
          <linearGradient
            id='paint7_linear_13'
            x1='52.776'
            x2='52.772'
            y1='65.544'
            y2='46.004'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#fff' />
            <stop offset='1' stopColor='#A4B0B2' />
          </linearGradient>
          <linearGradient
            id='paint8_linear_13'
            x1='52.77'
            x2='52.77'
            y1='49.21'
            y2='62.33'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#F7F3CE' />
            <stop offset='1' stopColor='#E0DCBC' />
          </linearGradient>
          <linearGradient
            id='paint9_linear_13'
            x1='17.776'
            x2='17.772'
            y1='23.544'
            y2='4.004'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#fff' />
            <stop offset='1' stopColor='#A4B0B2' />
          </linearGradient>
          <linearGradient
            id='paint10_linear_13'
            x1='17.77'
            x2='17.77'
            y1='7.21'
            y2='20.33'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#F7F3CE' />
            <stop offset='1' stopColor='#E0DCBC' />
          </linearGradient>
          <linearGradient
            id='paint11_linear_13'
            x1='52.776'
            x2='52.772'
            y1='23.544'
            y2='4.004'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#fff' />
            <stop offset='1' stopColor='#A4B0B2' />
          </linearGradient>
          <linearGradient
            id='paint12_linear_13'
            x1='52.77'
            x2='52.77'
            y1='7.21'
            y2='20.33'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#F7F3CE' />
            <stop offset='1' stopColor='#E0DCBC' />
          </linearGradient>
          <clipPath id='clip0_13'>
            <path fill='#fff' d='M0 0h70v70H0z' />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
