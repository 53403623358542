export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      if (reader.result == null) {
        return reject(new Error('Could not read file'));
      }
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.onabort = function (error) {
      reject(error);
    };
  });
};
