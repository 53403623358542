import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'

import Grid from '@material-ui/core/Grid'
import { Dice } from '../dice'

const numToChar = {
  1: 'P', // пешка
  2: 'N', // лошадь
  3: 'B', // слон
  4: 'R', // тура
  5: 'Q', // королева
  6: 'K' // король
}

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

function Dices({ size, color, figures, lastDices }) {
  return (
    <React.Fragment>
      <Grid item xs={4} className='nonActivePiece'>
        <Dice
          size={size}
          figures={figures}
          noanimation
          index={lastDices[0]}
          color={color}
        />
      </Grid>
      <Grid item xs={4} className='nonActivePiece'>
        <Dice
          size={size}
          noanimation
          figures={figures}
          index={lastDices[1]}
          color={color}
        />
      </Grid>
      <Grid item xs={4} className='nonActivePiece'>
        <Dice
          size={size}
          noanimation
          figures={figures}
          index={lastDices[2]}
          color={color}
        />
      </Grid>
    </React.Fragment>
  )
}

function DicePieces(props) {
  const {
    size,
    player,
    legalPieces,
    currentPlayerDice,
    activeColor,
    prefix,
    diceClickRnd,
    lastDices,
    noanimation,
    figures
  } = props

  const [dices, setDices] = useState()

  const prevDices = usePrevious(dices)

  const [timer0, setTimer0] = useState()
  const [timer1, setTimer1] = useState()
  const [timer2, setTimer2] = useState()

  const [timerForActivate, setTimerForActivate] = useState(2000)

  const [activeDices, setActiveDices] = useState([
    'activePiece',
    'activePiece',
    'activePiece'
  ])

  useLayoutEffect(() => {
    setActiveDices(['activePiece', 'activePiece', 'activePiece'])
    setTimerForActivate(2000)
    setTimeout(() => {
      setTimerForActivate(200)
    }, 3000)
  }, [diceClickRnd])

  function parseDice() {
    if (currentPlayerDice.length === 3) {
      const result = currentPlayerDice.map((p, i) => {
        const activeClass =
          p.active &&
          legalPieces.find((lp) => lp.toUpperCase() === numToChar[p.figure])
            ? 'activePiece'
            : 'nonActivePiece'

        const index = p.figure

        if (i === 0 && timer0) clearTimeout(timer0)
        if (i === 1 && timer1) clearTimeout(timer1)
        if (i === 2 && timer2) clearTimeout(timer2)

        const timer = setTimeout(() => {
          setActiveDices((activeDices) => [
            i === 0 ? activeClass : activeDices[0],
            i === 1 ? activeClass : activeDices[1],
            i === 2 ? activeClass : activeDices[2]
          ])
        }, timerForActivate)

        if (i === 0) setTimer0(timer)
        if (i === 1) setTimer1(timer)
        if (i === 2) setTimer2(timer)

        return { class: activeClass, index, p }
      })

      setDices(result)
    } else {
      setDices(null)
    }
  }

  useLayoutEffect(() => {
    if (legalPieces.legalPieces !== 0) {
      parseDice()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlayerDice, activeColor, legalPieces])

  useLayoutEffect(() => {
    if (legalPieces.legalPieces === 0) {
      setActiveDices(['nonActivePiece', 'nonActivePiece', 'nonActivePiece'])
    }
  }, [legalPieces])

  useLayoutEffect(() => {
    setTimerForActivate(noanimation ? 100 : 2000)
    setTimeout(
      () => {
        setTimerForActivate(200)
      },
      noanimation ? 100 : 3000
    )
    setActiveDices(['activePiece', 'activePiece', 'activePiece'])
  }, [activeColor])

  if (dices) {
    return (
      <React.Fragment>
        {dices.map((dice, i) => {
          return (
            <Grid xs={4} item key={i} className={activeDices[i]}>
              <Dice
                size={size}
                prefix={prefix}
                figures={figures}
                index={dice.index}
                prevIndex={prevDices ? prevDices[i].index : lastDices[i]}
                color={activeColor}
                noanimation={noanimation}
                player={player}
              />
            </Grid>
          )
        })}
      </React.Fragment>
    )
  } else {
    return (
      <Dices
        size={size}
        color={activeColor}
        figures={figures}
        lastDices={lastDices}
      />
    )
  }
}

export default DicePieces
