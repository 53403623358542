import endpoints from 'api/endpoints';
import { api } from 'api/rest';

export class TransactionService {
  static async importJackStats(data) {
    const response = await api.post(endpoints.importJackStats, data);
    return response;
  }
  static async getTransactionsStats(userId, data) {
    const response = await api.get(`${endpoints.transactionsStats}/${userId}`, {
      params: data,
    });
    return response;
  }
}
