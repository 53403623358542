import { makeStyles } from '@material-ui/core/styles';

export const dialogStyles = makeStyles(() => ({
  dialog: {
    zIndex: 10000,
  },
  paper: {
    maxWidth: 500,
    width: '100%',
  },
  form: {
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
}));
