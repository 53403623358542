import { makeAutoObservable } from 'mobx';

import CountryStore from './country';

class RootStore {
  constructor() {
    makeAutoObservable(this);
  }
  countryStore = new CountryStore();
}

export const store = new RootStore();
