import { makeStyles } from '@material-ui/styles';
import React, { useRef, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    margin: '5px 0',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridRowGap: '10px',
  },
  topWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  uploadText: {
    fontSize: 16,
    color: '#000',
    fontWeight: '400',
    cursor: 'pointer',
  },
  inputFile: {
    display: 'none',
  },
  closeIconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  filenameText: {
    fontSize: 16,
    color: '#000',
    fontWeight: '400',
    justifySelf: 'center',
  },
});

// TODO: remove this component and use src/components/UploadImage
const UploadImage = ({ onUpload, onImageRemove, image }) => {
  const styles = useStyles();
  const imageRef = useRef();
  const [isLoading, setLoading] = useState(false);

  const handleChange = async (e) => {
    setLoading(true);
    await onUpload(e.target.files);
    setLoading(false);
  };

  const onUploadClick = () => {
    imageRef.current.click();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.topWrapper}>
        <input
          accept="image/png, image/gif, image/jpeg"
          type="file"
          ref={imageRef}
          onChange={handleChange}
          className={styles.inputFile}
        />
        <div className={styles.uploadText} onClick={onUploadClick}>
          Загрузить
        </div>
        {image && (
          <div className={styles.closeIconWrapper}>
            <CloseIcon
              onClick={() => {
                imageRef.current.value = '';
                onImageRemove();
              }}
              className={styles.closeIcon}
            />
          </div>
        )}
      </div>
      <span className={styles.filenameText}>{isLoading ? '......' : image}</span>
    </div>
  );
};

export default UploadImage;
