import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { useAuthAPI } from 'hooks/use-auth-api';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { errorService } from 'services/alert/services';
import endpoints from 'api/endpoints';
import { NotificationService } from '../../services/api/notification';
import CreateNotificationModal from './create-notification-modal';
import { mapActionTypeValue, mapActionType } from './utils';

const styles = {
  increaseBalance: {
    color: '#2eb92e',
  },
  decreaseBalance: {
    color: '#e01919',
  },
  takingReservedBalance: {
    color: '#707070',
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

const pageSize = 15;
const pageSizeOptions = [15, 20, 30, 40];

const useStyles = makeStyles(styles);

const Notifications = () => {
  const classes = useStyles();
  const request = useAuthAPI();
  const [isCreateNotificationOpen, setCreateNotificationOpen] = useState(false);

  const tableRef = useRef();

  const onData = async (query) => {
    try {
      const response = await request(`${endpoints.notifications}`, {
        limit: query.pageSize,
        offset: query.page * query.pageSize,
      });
      return {
        data: response.data.data,
        page: query.page,
        totalCount: response.data.total,
        pageSize: query.pageSize,
      };
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  const onNotificationCreate = async (rowData) => {
    const data = {
      locale: {
        title: rowData.title,
        description: rowData.description,
      },
      actions: rowData.actions,
      nickname: rowData.nickname,
    };
    try {
      await NotificationService.createNotification(data);
      tableRef.current.retry();
      setCreateNotificationOpen(false);
    } catch (e) {
      errorService.sendError(e?.response?.data?.message ?? e.message);
    }
  };

  const onRowDelete = async (rowData) => {
    try {
      await NotificationService.deleteNotification(rowData._id);
      tableRef.current.retry();
    } catch (e) {
      errorService.sendError(e?.response?.data?.message ?? e.message);
    }
  };

  return (
    <div>
      <CreateNotificationModal
        open={isCreateNotificationOpen}
        handleClose={() => {
          setCreateNotificationOpen(false);
        }}
        onSubmit={onNotificationCreate}
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Управление уведомлениями</p>
            </CardHeader>
            <CardBody>
              <MaterialTable
                title="Уведомления"
                tableRef={tableRef}
                localization={{
                  body: {
                    editTooltip: 'Редактировать',
                    editRow: {
                      deleteText: 'Вы уверены что хотите удалить уведомление?',
                    },
                  },
                  toolbar: { searchPlaceholder: 'Поиск' },
                  pagination: { labelRowsSelect: 'cтрок' },
                  header: {
                    actions: 'Действия',
                  },
                }}
                actions={[
                  {
                    icon: 'add',
                    tooltip: 'Создать уведомление',
                    isFreeAction: true,
                    onClick: () => setCreateNotificationOpen(true),
                  },
                ]}
                columns={[
                  {
                    title: 'ID',
                    field: '_id',
                    editable: false,
                  },
                  {
                    title: 'Заголовок',
                    field: 'locale.label.title',
                    render: (rowData) => {
                      if (rowData?.locale?.label?.title) {
                        return <div>{rowData?.locale?.label?.title}</div>;
                      }
                      return (
                        <div>
                          {rowData?.locale?.title?.ru} <br />
                          {rowData?.locale?.title?.en}
                        </div>
                      );
                    },
                  },
                  {
                    title: 'Описание',
                    field: 'locale.label.description',
                    render: (rowData) => {
                      if (rowData?.locale?.description?.title) {
                        return <div>{rowData?.locale?.description?.title}</div>;
                      }
                      return (
                        <div>
                          {rowData?.locale?.description?.ru} <br />
                          {rowData?.locale?.description?.en}
                        </div>
                      );
                    },
                  },
                  {
                    title: 'Тип действия',
                    field: 'actionType',
                    render: (rowData) => {
                      return rowData?.actions?.map?.((action, i) => (
                        <div key={i}>{mapActionTypeValue(action.actionType)}</div>
                      ));
                    },
                  },
                  {
                    title: 'Пользователь',
                    field: 'user',
                    render: (rowData) => {
                      return (
                        <>
                          {rowData?.user?.nickname}
                          <br />
                          {rowData?.user?.email}
                        </>
                      );
                    },
                  },
                  {
                    title: 'Тип',
                    field: 'type',
                    render: (rowData) => {
                      return mapActionType(rowData.type);
                    },
                  },
                ]}
                options={{
                  filtering: false,
                  debounceInterval: 700,
                  search: false,
                  pageSize,
                  pageSizeOptions,
                }}
                data={onData}
                editable={{
                  onRowDelete,
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Notifications;
