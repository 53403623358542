import endpoints from 'api/endpoints';
import { api } from 'api/rest';

export class AdminService {
  static async addAdmin(id) {
    const response = await api.post(`${endpoints.users}/admin`, {
      id
    });
    return response;
  }
  static async addSupport(id) {
    const response = await api.post(`${endpoints.adminSupport}`, {
      id
    });
    return response;
  }
  static async getAdmins() {
    const response = await api.get(`${endpoints.users}/admins`);
    return response;
  }
}
