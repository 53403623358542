import { makeStyles } from '@material-ui/core/styles';

export const dialogStyles = makeStyles(() => ({
  dialog: {
    zIndex: 10000,
  },
  paper: {
    maxWidth: 500,
    width: '100%',
  },
  form: {
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
  actionsWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridRowGap: '20px',
    margin: '10px 0',
  },
  errorText: {
    color: 'red',
    fontSize: 12,
  },
  actionButtons: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  actionButton: {
    justifySelf: 'center',
  },
  stickersList: {
    margin: '10px 0',
  },
  stickerItem: {
    width: '95%',
    margin: '0 auto',
    position: 'relative',
  },
  emptyStickers: {
    width: '100%',
    padding: '10px 0',
    display: 'grid',
    placeItems: 'center',
  },
  addStickerWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    top: 0,
  },
}));
