import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import cn from 'classnames';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';

import { errorService } from 'services/alert/services';
import { useStore } from 'hooks/use-store';
import { UserService } from 'services/api/user';

import { useStyles } from './styles';

const gameTypes = {
  dicechess: 'Шахматы',
  jackpoker: 'Покер',
  slots: 'Слоты',
  liveDealer: 'Дилеры',
  sports: 'Ставки'
};

export const UpdateBlockedGames = ({ id, profile, open, onClose }) => {
  const classes = useStyles();
  const { externalGames } = useStore();
  const [blockedGames, setBlockedGames] = useState(new Set([]));
  const [isLoading, setLoading] = useState(false);
  const [blockedSections, setBlockedSections] = useState(() => new Set([]));

  useEffect(() => {
    if (profile) {
      setBlockedGames(new Set(profile.gameSettings.blockedExternalGames));
      setBlockedSections(new Set(profile.gameSettings.blockedSections));
    }
  }, [profile]);

  const onUpdate = async () => {
    setLoading(true);
    try {
      await UserService.updateBlockedGames(
        id,
        Array.from(blockedSections),
        Array.from(blockedGames),
      );
    } catch (e) {
      errorService.sendError(e.message);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const toggleSectionChange = async (name) => {
    if (blockedSections.has(name)) blockedSections.delete(name);
    else blockedSections.add(name);
    setBlockedSections(new Set(blockedSections));
  };

  const toggleExternalGame = (gameId) => {
    if (blockedGames.has(gameId)) blockedGames.delete(gameId);
    else blockedGames.add(gameId);
    setBlockedGames(new Set(blockedGames));
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Заблокировать:</DialogTitle>
        <DialogContent>
          <Grid container className={classes.fullWidth}>
            <Grid item className={classes.fullWidth}>
              <Grid container className={classes.fullWidth}>
                <Grid item xs={12}>
                  <p className={classes.bold}>Разделы:</p>
                  <FormGroup>
                    {Object.entries(gameTypes).map(([value, label]) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={blockedSections.has(value)}
                            onChange={() => toggleSectionChange(value)}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label={<span className={classes.formControlLabel}>{label}</span>}
                      />
                    ))}
                  </FormGroup>
                </Grid>
                <Grid>
                  <p className={classes.bold}>Игры:</p>
                  <FormGroup className={classes.externalGamesList}>
                    {externalGames.map((game) => (
                      <div className={classes.externalGameItem}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={blockedGames.has(game._id)}
                              onChange={() => toggleExternalGame(game._id)}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label={
                            <span className={classes.formControlLabel}>
                              {game.name
                                ? `${game.name}, ${game.gameId}`
                                : game.gameId}
                            </span>
                          }
                        />
                      </div>
                    ))}
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Закрыть
          </Button>
          <Button color="primary" variant="contained" onClick={onUpdate} disabled={isLoading}>
            Обновить
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
