import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  loaderContainer: {
    width: 450,
  },
  padding: { padding: 4 },
  bold: { fontWeight: 'bolder' },
  verificationInfoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  verificationPhotoHolder: {
    maxWidth: 450,
    width: '100%',
    marginBottom: 10,
  },
  verificationPhotoContainer: {
    width: '100%',
    paddingBottom: '100%',
    position: 'relative',
  },
  verificationPhotoWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  verificationInfoList: {},
  verificationInfoPhoto: {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    objectFit: 'cover',
  },
  imageShadow: {
    width: '100%',
    height: '100%',

    display: 'grid',
    placeItems: 'center',

    position: 'absolute',
    left: 0,
    top: 0,
    background: 'rgb(0, 0, 0, 0.4)',
    opacity: 0,
    transition: '0.3s ease',
    cursor: 'pointer',

    '&:hover': {
      opacity: 1,
    },
  },
  openFullScreenIcon: {
    fontSize: 50,
    color: '#fff',
    userSelect: 'none',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fill: 'currentColor',
  },
  verificationActions: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: 15,
    margin: '15px 0',
  },
  accordion: {
    borderRadius: '4px',
  },
  accordionSummary: {
    '& .MuiAccordionSummary-content': {
      display: 'grid',
      gridTemplateColumns: '1.5fr 1fr',
      alignItems: 'center',
    },
    '&.Mui-expanded': {
      minHeight: 40,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '4px 0',
    },
  },
  accordionHeading: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 400,
    minWidth: '33.33%',
    flexShrink: 0,
  },
  accordionSecondaryHeading: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 400,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: 0,
  },
  formControlLabel: {
    color: 'rgba(0, 0, 0, 0.87)!important',
    fontWeight: '300',
  },
  buttonWrapper: {
    display: 'flex',
    margin: '15px 0',
  },
});
