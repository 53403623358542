import { actionNamesLabels, clickTypeValueLabels, actionTypeLabels } from './constants';

export const mapActionName = (name) => {
  return actionNamesLabels[name] || name;
};

export const mapActionTypeValue = (type) => {
  return clickTypeValueLabels[type] || type;
};

export const mapActionType = (type) => {
  return actionTypeLabels[type] || type;
};
