import React, { useRef } from 'react';
import { DragLayer } from 'react-dnd';

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  left: 0,
  top: 0,
  zIndex: 1000000,
};

function getItemStyles(props) {
  const { initialOffset, currentOffset, clientOffset, width, height } = props;

  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }
  const { x, y } = clientOffset;

  const transform = `translate(${x - width / 2}px, ${y - height / 2}px)`;
  return {
    position: 'relative',
    transform,
    WebkitTransform: transform,
  };
}

const PieceDragLayer = (props) => {
  const { item, itemType, isDragging, pieces } = props;

  const ref = useRef();

  if (!isDragging || !itemType) {
    return [];
  }
  return (
    <div style={layerStyles}>
      <div
        ref={ref}
        style={getItemStyles({
          ...props,
          width: ref?.current?.offsetWidth,
          height: ref?.current?.offsetHeight,
        })}
      >
        {pieces[item.type]}
      </div>
    </div>
  );
};

export default DragLayer((monitor) => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  initialOffset: monitor.getInitialSourceClientOffset(),
  currentOffset: monitor.getSourceClientOffset(),
  clientOffset: monitor.getClientOffset(),
  isDragging: monitor.isDragging(),
}))(PieceDragLayer);
