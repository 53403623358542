export const variableNames = {
  pokerAvailability: 'Доступность покера',
  isGameCreationAllowed: 'Разрешено ли создание игр',
};

export const variableValueTypes = {
  string: 'Строка',
  boolean: 'Логический',
  number: 'Число',
};
