import React, { useState, useLayoutEffect } from 'react'
import DicePieces from './dice-pieces'

export const Dices = (props) => {
  const {
    size,
    player,
    noanimation,
    legalPieces,
    currentPlayerDice,
    activeColor,
    prefix,
    diceClickRnd,
    legalPiecesAndMoves,
    badLuck,
    lastDices,
    figures
  } = props

  const [currentPlayerDiceLocal, setCurrentPlayerDiceLocal] = useState([])
  const [activeColorLocal, setActiveColorLocal] = useState('')

  useLayoutEffect(() => {
    const totalEmptyDices = currentPlayerDice
      .filter((d) => d.active === false)
      .filter((d) => d.figure === 0).length

    if (totalEmptyDices !== 3) {
      setCurrentPlayerDiceLocal(currentPlayerDice)
      setActiveColorLocal(activeColor)
    }
    // eslint-disable-next-line
  }, [legalPieces, currentPlayerDice])

  return (
    <DicePieces
      figures={figures}
      size={size}
      player={player}
      lastDices={lastDices}
      badLuck={badLuck}
      legalPiecesAndMoves={legalPiecesAndMoves}
      diceClickRnd={diceClickRnd}
      prefix={prefix}
      legalPieces={legalPieces}
      currentPlayerDice={currentPlayerDiceLocal}
      activeColor={activeColorLocal}
      noanimation={noanimation}
    />
  )
}

Dices.defaultProps = {
  size: 60,
  activeColor: 'w',
  noanimation: false,
  badLuck: false,
  legalPiecesAndMoves: [],
  legalPieces: [],
  diceClickRnd: Math.random(),
  lastDices: [1, 1, 1],
  currentPlayerDice: [
    { active: true, figure: 1 },
    { active: true, figure: 1 },
    { active: true, figure: 1 }
  ]
}
