import React, { useMemo } from 'react';

import * as Yup from 'yup';
import { Formik } from 'formik';
import cn from 'classnames';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import UploadImageInput from 'components/UploadImageInput';

import { dialogStyles } from './styles';
import { errorService } from 'services/alert/services';

// TODO: render list of stickers like that https://mui.com/material-ui/react-image-list/#title-bar-below-image-standard
const CreateOrUpdateStickerPackModal = ({
  open,
  handleClose,
  mode,
  stickerPack,
  onCreate,
  onUpdate,
}) => {
  const styles = dialogStyles();

  const handleSubmit = async (values) => {
    try {
      if (mode === 'create') await onCreate(values);
      else await onUpdate(stickerPack._id, values);
    } catch (e) {
      errorService.sendError(e.message);
    } finally {
      handleClose();
    }
  };

  const initialValues = useMemo(() => {
    if (mode === 'create') {
      return {
        name: '',
        stickers: [],
      };
    }
    return {
      name: stickerPack.name,
      stickers: stickerPack.stickers,
    };
  }, [mode, stickerPack]);

  const addNewSticker = (setFieldValue, stickers) => {
    const emptySticker = {
      id: '',
      filename: '',
    };
    setFieldValue('stickers', [...stickers, emptySticker]);
  };

  const removeSticker = (setFieldValue, stickers, indexToRemove) => {
    console.log({ setFieldValue, stickers, indexToRemove });
    setFieldValue(
      'stickers',
      stickers.filter((st, i) => i !== indexToRemove),
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className={styles.dialog}
        PaperProps={{ className: styles.paper }}
      >
        <DialogTitle>{mode === 'create' ? 'Создать' : 'Обновить'} игру</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Введите имя'),
              stickers: Yup.array().of(
                Yup.object().shape({
                  id: Yup.string().required('Введите ID'),
                  filename: Yup.string().required('Загрузите изображение'),
                }),
              ),
            })}
            validateOnChange
            onSubmit={handleSubmit}
            render={({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              touched,
              values,
              errors,
            }) => {
              return (
                <form onSubmit={handleSubmit} className={styles.form}>
                  <FormControl className={styles.formControl} size="small">
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.name && touched.name)}
                      name="name"
                      margin="dense"
                      label="Имя(пример: classic)"
                      value={values.name}
                      placeholder="Введите имя"
                    />
                    <div className={styles.errorText}>{touched.name ? errors.name : ''}</div>
                  </FormControl>
                  {values.stickers?.length === 0 && (
                    <div className={styles.emptyStickers}>
                      <span>Нету стикеров</span>
                    </div>
                  )}
                  <div className={styles.stickersList}>
                    {/* <ImageList sx={{ width: 500, height: 450 }}>
                      {values?.stickers?.map((sticker, i) => (
                        <ImageListItem key={i}>
                          <img
                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item.img}?w=248&fit=crop&auto=format`}
                            alt={item.title}
                            loading="lazy"
                          />
                          <ImageListItemBar
                            title={item.title}
                            subtitle={<span>by: {item.author}</span>}
                            position="below"
                          />
                        </ImageListItem>
                      ))}
                    </ImageList> */}
                    {values.stickers?.map((sticker, i) => (
                      <div className={styles.stickerItem} key={i}>
                        <FormControl className={cn(styles.formControl)} size="small">
                          <TextField
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors?.stickers?.[i]?.id && touched?.stickers?.[i]?.id}
                            name={`stickers.${i}.id`}
                            margin="none"
                            label="ID"
                            value={sticker.id}
                            placeholder="Введите ID"
                          />
                          <UploadImageInput
                            image={sticker?.filename}
                            onChange={(value) => setFieldValue(`stickers.${i}.filename`, value)}
                          />
                          <div className={styles.errorText}>
                            {touched?.stickers?.[i]?.id ? !!errors?.stickers?.[i]?.id : ''}
                          </div>
                          <CloseIcon
                            onClick={() => {
                              removeSticker(setFieldValue, values.stickers, i);
                            }}
                            className={styles.closeIcon}
                          />
                        </FormControl>
                      </div>
                    ))}
                  </div>
                  <div className={styles.addStickerWrapper}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        addNewSticker(setFieldValue, values.stickers);
                      }}
                    >
                      <AddIcon />
                    </Button>
                  </div>
                  <DialogActions>
                    <Button color="primary" variant="contained" type="submit">
                      {mode === 'create' ? 'Создать' : 'Обновить'}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateOrUpdateStickerPackModal;
