export const animations = [
  {
    '0%': {
      animationTimingFunction: 'easy-out',
      transform: 'translate3d(0,0,0)'
    },

    '20%': {
      animationTimingFunction: 'easy-out',
      transform: 'translate3d(0,-25px,40px)'
    },

    '40%': {
      animationTimingFunction: 'easy-out',
      transform: 'translate3d(0,-25px,100px)'
    },

    '43%': {
      animationTimingFunction: 'easy-out',
      transform: 'translate3d(0,-25px,50px)'
    },

    '53%': {
      animationTimingFunction: 'easy-out',
      transform: 'translate3d(0,0,0)'
    },

    '100%': {
      animationTimingFunction: 'easy-out',
      transform: 'translate3d(0,0,0)'
    }
  },
  {
    '0%': {
      animationTimingFunction: 'cubic-bezier(0.1, 0.7, 1.0, 0.1)',
      transform: 'translate3d(0,0,0)'
    },

    '20%': {
      animationTimingFunction: 'cubic-bezier(0.1, 0.7, 1.0, 0.1)',
      transform: 'translate3d(0,-25px,40px)'
    },

    '40%': {
      animationTimingFunction: 'cubic-bezier(0.1, 0.7, 1.0, 0.1)',
      transform: 'translate3d(0,-25px,100px)'
    },

    '43%': {
      animationTimingFunction: 'cubic-bezier(0.1, 0.7, 1.0, 0.1)',
      transform: 'translate3d(0,-25px,50px)'
    },

    '53%': {
      animationTimingFunction: 'cubic-bezier(0.1, 0.7, 1.0, 0.1)',
      transform: 'translate3d(0,0,0)'
    },

    '100%': {
      animationTimingFunction: 'cubic-bezier(0.1, 0.7, 1.0, 0.1)',
      transform: 'translate3d(0,0,0)'
    }
  },
  {
    '0%': {
      animationTimingFunction: 'easy-in',
      transform: 'translate3d(0,0,0)'
    },

    '20%': {
      animationTimingFunction: 'easy-in',
      transform: 'translate3d(0,-25px,40px)'
    },

    '40%': {
      animationTimingFunction: 'easy-in',
      transform: 'translate3d(0,-25px,100px)'
    },

    '43%': {
      animationTimingFunction: 'easy-in',
      transform: 'translate3d(0,-25px,50px)'
    },

    '53%': {
      animationTimingFunction: 'easy-in',
      transform: 'translate3d(0,0,0)'
    },

    '100%': {
      animationTimingFunction: 'easy-in',
      transform: 'translate3d(0,0,0)'
    }
  }
]
