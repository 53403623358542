import endpoints from 'api/endpoints';
import { api } from 'api/rest';

export class NotificationService {
  static async createNotification(data) {
    const response = await api.post(endpoints.notifications, data);
    return response;
  }
  static async deleteNotification(id) {
    const response = await api.delete(`${endpoints.notifications}/${id}`);
    return response;
  }
}
