import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  fullWidth: {
    width: '100%',
    flexDirection: 'column',
  },
  form: {
    width: 500,
    margin: '0 0',
  },
  cellItem: {
    width: '100%',
    margin: '0 0 15px',
  },
  errorText: {
    width: '100%',
    color: '#f44336',
  },
  loaderContainer: {
    width: 450,
  },
  padding: { padding: 4 },
  bold: { fontWeight: 'bolder' },
  externalGamesList: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '500px',
  },
  externalGameItem: {
    width: '100%',
  },
  formControlLabel: {
    color: 'rgba(0, 0, 0, 0.87)!important',
    fontWeight: '300',
  },
});
