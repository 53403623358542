import { useState, useEffect } from 'react';
import axios from 'axios';

import { useAuth } from 'hooks/use-auth';

export const useDeleteAPI = ({ multipart = false }) => {
  const auth = useAuth();

  const [data, setData] = useState<any>();

  const [params, setParams] = useState({
    url: undefined,
    onSuccess: () => {},
    onError: () => {},
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const headers = { Authorization: `Bearer ${auth.token}` };

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        const result = await axios({
          method: 'delete',
          url: params.url,
          headers: multipart
            ? { 'Content-Type': 'multipart/form-data', ...headers }
            : { ...headers },
        });
        setData(result.data);
        params.onSuccess();
      } catch (error) {
        setData({
          response: error.response.data,
          error: error.message,
        });
        setIsError(true);
        params.onError();
      }
      setIsLoading(false);
    };

    if (params && params.url) fetchData();
  }, [params]);

  return [{ data, isLoading, isError }, setParams] as [
    { data: any[]; isLoading: boolean; isError: boolean },
    (data?: any) => void,
  ];
};
