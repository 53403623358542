import endpoints from 'api/endpoints';
import { api } from 'api/rest';

export class StaticBlockService {
  static async getAll() {
    const { data } = await api.get(endpoints.staticBlock);
    return data;
  }
  static async getByName(name: string) {
    const { data } = await api.get(`${endpoints.staticBlock}/${name}`);
    return data;
  }
  static async create(name: string, value: any) {
    const { data } = await api.post(endpoints.staticBlock, {
      name,
      data: value,
    });
    return data;
  }
  static async update(id: string, name: string, value: any) {
    const { data } = await api.put(`${endpoints.staticBlock}/${id}`, {
      name,
      data: value,
    });
    return data;
  }
  static async delete(name: string) {
    const { data } = await api.delete(`${endpoints.staticBlock}/${name}`);
    return data;
  }
}
