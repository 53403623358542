import { useStyles } from './styles';

interface ITableTitleProps {
  children: any;
}

const TableTitle = ({ children }: ITableTitleProps) => {
  const classes = useStyles();
  return <p className={classes.cardTitleWhite}>{children}</p>;
};

export default TableTitle;
