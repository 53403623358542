import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function B1({ width, height }) {
  return (
    <SVGUniqueID>
      <svg
        id='b-01_svg__Layer_1'
        x={0}
        y={0}
        viewBox='0 0 70 70'
        xmlSpace='preserve'
        width={width}
        height={height}
      >
        <style />
        <linearGradient
          id='b-01_svg__SVGID_1_'
          gradientUnits='userSpaceOnUse'
          x1={35}
          y1={69.5}
          x2={35}
          y2={0.5}
        >
          <stop offset={0} stopColor='#bcc5c7' />
          <stop offset={0.65} stopColor='#e6e8e9' />
        </linearGradient>
        <path
          d='M10 69.5C4.8 69.5.5 65.2.5 60V10C.5 4.8 4.8.5 10 .5h50c5.2 0 9.5 4.3 9.5 9.5v50c0 5.2-4.3 9.5-9.5 9.5H10z'
          fill='url(#b-01_svg__SVGID_1_)'
        />
        <path
          d='M60 1c5 0 9 4 9 9v50c0 5-4 9-9 9H10c-5 0-9-4-9-9V10c0-5 4-9 9-9h50m0-1H10C4.5 0 0 4.5 0 10v50c0 5.5 4.5 10 10 10h50c5.5 0 10-4.5 10-10V10c0-5.5-4.5-10-10-10z'
          fill='#fff'
        />
        <g>
          <linearGradient
            id='b-01_svg__SVGID_2_'
            gradientUnits='userSpaceOnUse'
            x1={20.173}
            y1={39.156}
            x2={50.463}
            y2={39.156}
          >
            <stop offset={0} stopColor='#3a3a3f' />
            <stop offset={1} stopColor='#040408' />
          </linearGradient>
          <path
            d='M49.3 52.6C39.2 47 39.2 41.4 39.2 41.4v-1.9l4 .3c1 .2 1.9-.5 2-1.4l.2-1c.2-1-.5-1.9-1.4-2l-3-1.8c1.7-1.5 2.8-3.8 2.8-6.2 0-4.6-3.8-8.4-8.4-8.4-4.6 0-8.4 3.8-8.4 8.4 0 2.5 1.1 4.7 2.8 6.2l-3 1.8c-1 .2-1.6 1.1-1.4 2l.2 1c.2 1 1.1 1.6 2 1.4l4-.3v1.9s0 5.6-10.1 11.2c0 0-1.1 1.1-1.1 2.2v4.5H50.7v-4.5c-.2-1.1-1.4-2.2-1.4-2.2z'
            fill='url(#b-01_svg__SVGID_2_)'
          />
          <linearGradient
            id='b-01_svg__SVGID_3_'
            gradientUnits='userSpaceOnUse'
            x1={35.318}
            y1={60.849}
            x2={35.318}
            y2={17.462}
          >
            <stop offset={0.001} stopColor='#fff' />
            <stop offset={1} stopColor='#a4b0b2' />
          </linearGradient>
          <path
            d='M35.3 19c4.6 0 8.4 3.8 8.4 8.4 0 2.5-1.1 4.7-2.8 6.2l3 1.8c1 .2 1.6 1.1 1.4 2l-.2 1c-.1.9-.9 1.5-1.7 1.5h-.3l-4-.3v1.9s0 5.6 10.1 11.2c0 0 1.1 1.1 1.1 2.2v4.5H20.2v-4.5c0-1.1 1.1-2.2 1.1-2.2C31.4 47 31.4 41.4 31.4 41.4v-1.9l-4 .3h-.3c-.8 0-1.6-.6-1.7-1.5l-.2-1c-.2-1 .5-1.9 1.4-2l3-1.8c-1.7-1.5-2.8-3.8-2.8-6.2.1-4.6 3.9-8.3 8.5-8.3m0-1.5c-5.5 0-9.9 4.4-9.9 9.9 0 2.1.7 4.2 1.9 5.9l-1.2.7c-.7.2-1.3.6-1.8 1.2-.5.7-.7 1.6-.6 2.4l.2 1c.3 1.6 1.6 2.7 3.2 2.7h.5l2.3-.2v.2s-.3 4.9-9.3 9.9l-.2.1-.1.1c-.3.3-1.6 1.6-1.6 3.3v6H52v-6c0-1.7-1.3-3-1.6-3.3l-.1-.1-.2-.1c-9.1-5-9.3-9.9-9.3-9.9v-.2l2.3.2h.5c1.6 0 2.9-1.1 3.2-2.7l.2-1c.1-.9-.1-1.7-.6-2.4-.4-.6-1.1-1.1-1.8-1.2l-1.3-.8c1.2-1.7 1.9-3.8 1.9-5.9 0-5.4-4.4-9.8-9.9-9.8z'
            fill='url(#b-01_svg__SVGID_3_)'
          />
        </g>
      </svg>
    </SVGUniqueID>
  )
}
