import React, { useEffect, useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_FullScreenToggleButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import Box from '@material-ui/core/Box';
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import Card from 'components/Card/Card.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import { StaticBlockService } from 'services/api/static-block';
import { errorService } from 'services/alert/services';

import CreateOrUpdateStaticBlockModal from './create-or-update';

const useStyles = makeStyles({
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: 300,
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
});

const StaticBlockPage = () => {
  const classes = useStyles();

  const [selectedName, setSelectedName] = useState<string | null>(null);
  const [openCreateOrUpdateDialog, setOpenCreateOrUpdateDialog] = useState(false);

  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const columns = useMemo(
    () => [
      {
        header: 'ID',
        accessorKey: '_id',
        Cell: ({ cell }) => {
          return cell.getValue();
        },
        enableColumnFilterModes: false,
        enableSorting: false,
      },
      {
        header: 'Имя',
        accessorKey: 'name',
        Cell: ({ cell }) => {
          return cell.getValue();
        },
        enableColumnFilterModes: false,
        enableSorting: false,
      },
    ],
    [],
  );

  useEffect(() => {
    getAll();
  }, []);

  const getAll = async () => {
    try {
      const data = await StaticBlockService.getAll();
      setData(data.staticBlocks);
    } catch (e) {
      errorService.sendError(e.message);
    }
  };
  const onDelete = async (name: string) => {
    if (!window.confirm(`Вы уверены что хотите удалить?`)) {
      return;
    }
    try {
      await StaticBlockService.delete(name);
      getAll();
    } catch (e) {
      errorService.sendError(e.message);
    }
  };
  const onOpenUpdateRowModal = (name: string) => {
    setSelectedName(name);
    setOpenCreateOrUpdateDialog(true);
  };
  const onOpenCreateRowModal = () => {
    setSelectedName(null);
    setOpenCreateOrUpdateDialog(true);
  };

  return (
    <div>
      <GridContainer>
        <CreateOrUpdateStaticBlockModal
          name={selectedName}
          open={openCreateOrUpdateDialog}
          handleClose={() => {
            setSelectedName(null);
            setOpenCreateOrUpdateDialog(false);
          }}
          onSuccess={() => {
            getAll();
          }}
        />
        {/* @ts-ignore */}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Управление CMS блоками</p>
            </CardHeader>
            <CardBody>
              <MaterialReactTable
                columns={columns}
                data={data}
                enableColumnFilterModes
                enableColumnOrdering
                enableGrouping
                enableRowActions
                initialState={{ showColumnFilters: true }}
                positionToolbarAlertBanner="bottom"
                muiTableBodyRowProps={() => ({
                  style: {
                    cursor: 'pointer',
                  },
                })}
                renderRowActions={({ row }) => (
                  // @ts-ignore
                  <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                    <IconButton
                      onClick={() => {
                        onOpenUpdateRowModal((row.original as Record<string, any>).name);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        onDelete((row.original as Record<string, any>).name);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
                renderTopToolbar={({ table }) => {
                  return (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Box>
                        <IconButton
                          onClick={() => {
                            onOpenCreateRowModal();
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                        <MRT_ShowHideColumnsButton table={table} />
                        <MRT_FullScreenToggleButton table={table} />
                        <MRT_ToggleDensePaddingButton table={table} />
                        <MRT_ToggleFiltersButton table={table} />
                      </Box>
                    </Box>
                  );
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default StaticBlockPage;
