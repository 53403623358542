export class URLHelper {
  // newQuery: object or string
  static replaceSearchParams(newQuery) {
    const queryString =
      typeof newQuery === 'object'
        ? Object.keys(newQuery)
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(newQuery[key])}`)
            .join('&')
        : newQuery;
    const newUrl = `${window.location.pathname}?${queryString}`;
    window.history.replaceState({ path: newUrl }, '', newUrl);

    return newUrl;
  }
  static deleteSearchParam(parameter) {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    searchParams.delete(parameter);

    const newUrl = `${url.origin}${url.pathname}${searchParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);

    return newUrl;
  }
  static getSearchParam(param) {
    const searchParams = new URLSearchParams(window.location.search);
    const searchParam = searchParams.get(param);
    return searchParam;
  }
}
