import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { BeatLoader } from 'react-spinners';
import MaterialTable from 'material-table';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

import { useAPI } from 'hooks/use-api';
import { usePostAPI } from 'hooks/use-post-api';
import { usePutAPI } from 'hooks/use-put-api';

import { errorService, infoService, warnService } from 'services/alert/services';

import endpoints from 'api/endpoints';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

const useStyles = makeStyles(styles);

const titleSchema = Yup.object().shape({
  title: Yup.string().trim().required('Введите название'),
});

const thresholdSchema = Yup.object().shape({
  threshold: Yup.number().required('Введите порог'),
});

const rakeBackSchema = Yup.object().shape({
  rakeBack: Yup.number().required('Введите рейкбэк'),
});

function validateField(schema, data) {
  try {
    schema.validateSync(data);
  } catch (error) {
    return error.message;
  }
  return true;
}

export default function Ranks() {
  const [rankList, setRankList] = useState();

  const [{ data, isLoading, isError }, doGetRanks] = useAPI();
  const [
    { data: createRankData, isLoading: isCreateRankLoading, isError: isCreateRankError },
    doCreateRank,
  ] = usePostAPI({});
  const [
    { data: updateRankData, isLoading: isUpdateRankLoading, isError: isUpdateRankError },
    doUpdateRank,
  ] = usePutAPI({});

  useEffect(() => {
    if (data) setRankList(data);
  }, [data]);

  useEffect(() => {
    doGetRanks(endpoints.ranks);
  }, []);

  useEffect(() => {
    if (isError || isCreateRankError || isUpdateRankError)
      errorService.sendError('Unknown Error API!');
  }, [isError, isCreateRankError, isUpdateRankError]);

  useEffect(() => {
    if (isCreateRankError)
      errorService.sendError('Невозможно создать ранг: ' + createRankData.response.message);
  }, [isCreateRankError]);

  useEffect(() => {
    if (isUpdateRankError)
      errorService.sendError('Невозможно изменить ранг: ' + updateRankData.response.message);
  }, [isUpdateRankError]);

  function onRowAdd(newData) {
    return new Promise((resolve, reject) => {
      doCreateRank({
        url: endpoints.ranks,
        params: {
          ...newData,
          threshold: Number(newData.threshold),
          rakeBack: Number(newData.rakeBack),
        },
        onSuccess: ({ _id }) => {
          setRankList((rankList) => [{ ...newData, _id }, ...rankList]);
          infoService.sendInfo('Ранг был успешно создан!');
          resolve();
        },
        onError: () => {
          reject();
        },
      });
    });
  }

  function onRowUpdate(newData, oldData) {
    return new Promise((resolve, reject) => {
      doUpdateRank({
        url: `${endpoints.ranks}/${oldData._id}`,
        params: {
          title: newData.title,
          threshold: Number(newData.threshold),
          rakeBack: Number(newData.rakeBack),
          bonus: Number(newData.bonus),
        },
        onSuccess: () => {
          setRankList((rankList) =>
            rankList.map((rank) => (rank._id === newData._id ? { ...newData } : rank)),
          );
          infoService.sendInfo('Ранг был успешно изменен!');

          resolve();
        },
        onError: () => {
          reject();
        },
      });
    });
  }

  function onRowDelete(oldData) {
    return new Promise((resolve, reject) => {
      doUpdateRank({
        url: `${endpoints.ranks}/${oldData._id}`,
        params: { deleted: true },
        onSuccess: () => {
          setRankList((rankList) => rankList.filter((rank) => rank._id !== oldData._id));
          warnService.sendWarn('Ранг был успешно удален!');
          resolve();
        },
        onError: () => {
          reject();
        },
      });
    });
  }

  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Управление рангами</p>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Box textAlign="center">
                  <BeatLoader size={16} color="#00acc1" loading={true} />
                </Box>
              ) : (
                <MaterialTable
                  title="Ранги"
                  localization={{
                    body: {
                      editTooltip: 'Редактировать',
                      deleteTooltip: 'Удалить',
                      editRow: {
                        deleteText: 'Вы уверены что хотите удалить ранг?',
                      },
                    },
                    header: {
                      actions: 'Действия',
                    },
                  }}
                  columns={[
                    {
                      title: 'Название',
                      field: 'title',
                      sorting: false,
                      validate: (rowData) =>
                        validateField(titleSchema, {
                          title: rowData.title,
                        }),

                      render: (rowData) => rowData.title,
                    },
                    {
                      title: 'Кол-во игр',
                      field: 'threshold',
                      defaultSort: 'asc',
                      validate: (rowData) =>
                        validateField(thresholdSchema, {
                          threshold: rowData.threshold,
                        }),
                    },
                    {
                      title: 'Рейкбэк %',
                      field: 'rakeBack',
                      sorting: false,
                      validate: (rowData) =>
                        validateField(rakeBackSchema, {
                          rakeBack: rowData.rakeBack,
                        }),
                    },
                    {
                      title: 'Бонус',
                      field: 'bonus',
                    },
                    {
                      title: 'Кол. пользователей',
                      field: 'numberOfUsers',
                      editable: false,
                    },
                  ]}
                  data={rankList}
                  options={{
                    filtering: false,
                  }}
                  editable={{
                    isEditable: () => true,
                    onRowAddCancelled: () => console.log('Row adding cancelled'),
                    onRowUpdateCancelled: () => console.log('Row editing cancelled'),
                    onRowAdd,
                    onRowUpdate,
                    onRowDelete,
                  }}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
