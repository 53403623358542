import { EExternalGameProviderType } from "domain/external-game/external-game.types";

export const externalGameTypes = {
  slots: 'Слоты',
  liveDealer: 'Live Dealer',
  sportsBetting: "Sports Betting"
};

export const externalGameProviderTypes = {
  [EExternalGameProviderType.ALGNET]: 'AlgNet',
  [EExternalGameProviderType.EVENBET]: 'Evenbet',
};
