import React, { useEffect, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { BeatLoader } from 'react-spinners';
import MaterialTable from 'material-table';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { useAPI } from 'hooks/use-api';
import { usePostAPI } from 'hooks/use-post-api';
import { usePutAPI } from 'hooks/use-put-api';
import { useDeleteAPI } from 'hooks/use-delete-api';
import { errorService, infoService, warnService } from 'services/alert/services';
import endpoints from 'api/endpoints';

import { VersionService } from '../../services/api/version';

import { CreateOrUpdateVariableModal } from './create-or-update-variable-modal';
import { variableNames } from './constant';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};
// @ts-ignore
const useStyles = makeStyles(styles);

const nameSchema = Yup.object().shape({
  name: Yup.string().trim().required('Введите название'),
});

const valueSchema = Yup.object().shape({
  value: Yup.string().trim().required('Введите значение'),
});

function validateField(schema, data) {
  try {
    schema.validateSync(data);
  } catch (error) {
    return error.message;
  }
  return true;
}

export default function Variables() {
  const [variableList, setVariableList] = useState<any>();

  const [{ data, isLoading, isError }, doGetVariables] = useAPI();
  const [isOpenCreateOrUpdateModal, setOpenCreateOrUpdateModal] = useState(false);
  const [selectedVariable, setSelectedVariable] = useState(null);
  const [isUpdateVersionLoading, setUpdateVersionLoading] = useState(false);
  const [
    {
      data: createVariableData,
      isLoading: isCreateVariableLoading,
      isError: isCreateVariableError,
    },
    doCreateVariable,
  ] = usePostAPI({});
  const [
    {
      data: updateVariableData,
      isLoading: isUpdateVariableLoading,
      isError: isUpdateVariableError,
    },
    doUpdateVariable,
  ] = usePutAPI({});

  const [
    {
      data: deleteVariableData,
      isLoading: isDeleteVariableLoading,
      isError: isDeleteVariableError,
    },
    doDeleteVariable,
  ] = useDeleteAPI({});

  useEffect(() => {
    if (data) setVariableList(data);
  }, [data]);

  useEffect(() => {
    doGetVariables(endpoints.variables);
  }, []);

  useEffect(() => {
    if (isError || isCreateVariableError || isUpdateVariableError)
      errorService.sendError('Unknown Error API!');
  }, [isError, isCreateVariableError, isUpdateVariableError]);

  useEffect(() => {
    if (isCreateVariableError)
      errorService.sendError(
        // @ts-ignore
        'Невозможно создать переменную: ' + createVariableData.response.message,
      );
  }, [isCreateVariableError]);

  useEffect(() => {
    if (isUpdateVariableError)
      errorService.sendError(
        // @ts-ignore
        'Невозможно изменить переменную: ' + updateVariableData.response.message,
      );
  }, [isUpdateVariableError]);

  useEffect(() => {
    if (isDeleteVariableError)
      errorService.sendError(
        // @ts-ignore
        'Невозможно удалить переменную: ' + deleteVariableData.response.message,
      );
  }, [isDeleteVariableError]);

  function onRowAdd(newData) {
    return new Promise((resolve, reject) => {
      doCreateVariable({
        url: endpoints.variables,
        params: { ...newData },
        onSuccess: ({ _id }) => {
          setVariableList((variableList) => [{ ...newData, _id }, ...variableList]);
          infoService.sendInfo('Переменная была успешно создана!');
          // @ts-ignore
          resolve();
        },
        onError: () => {
          reject();
        },
      });
    });
  }

  function onRowUpdate(newData, oldData) {
    return new Promise((resolve, reject) => {
      doUpdateVariable({
        url: `${endpoints.variables}/${oldData._id}`,
        params: {
          name: newData.name,
          value: newData.value,
        },
        onSuccess: () => {
          setVariableList((variableList) =>
            variableList.map((variable) =>
              variable._id === oldData._id ? { _id: oldData._id, ...newData } : variable,
            ),
          );
          infoService.sendInfo('Переменная была успешно изменена!');
          // @ts-ignore
          resolve();
        },
        onError: () => {
          reject();
        },
      });
    });
  }

  function onRowDelete(oldData) {
    return new Promise((resolve, reject) => {
      doDeleteVariable({
        url: `${endpoints.variables}/${oldData._id}`,
        onSuccess: () => {
          setVariableList((variableList) =>
            variableList.filter((variable) => variable._id !== oldData._id),
          );
          warnService.sendWarn('Переменная была успешно удалена!');
          // @ts-ignore
          resolve();
        },
        onError: () => {
          reject();
        },
      });
    });
  }

  const onUpdateVersionClick = async () => {
    setUpdateVersionLoading(true);
    try {
      const response = await VersionService.updateVersion();
      if (!response.data.err) {
        infoService.sendInfo('Версия обновлена');
      }
    } catch (e) {
      errorService.sendError(`Произошла какая то ошибка: ${e.message}`);
    } finally {
      setUpdateVersionLoading(false);
    }
  };

  const classes = useStyles();
  return (
    <div>
      <CreateOrUpdateVariableModal
        open={isOpenCreateOrUpdateModal}
        mode={selectedVariable ? 'update' : 'create'}
        onCreate={onRowAdd}
        onUpdate={onRowUpdate}
        variable={selectedVariable}
        onClose={() => {
          setOpenCreateOrUpdateModal(false);
        }}
      />
      <GridContainer>
        {/* @ts-ignore */}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Управление настройками</p>
            </CardHeader>
            <Box style={{ margin: '10px 0 10px 20px' }}>
              <Button
                disabled={isUpdateVersionLoading}
                variant="contained"
                color="primary"
                onClick={onUpdateVersionClick}
              >
                Обновить версию
              </Button>
            </Box>
            <CardBody>
              {isLoading ? (
                <Box textAlign="center">
                  <BeatLoader size={16} color="#00acc1" loading={true} />
                </Box>
              ) : (
                <MaterialTable
                  title="Настройки"
                  actions={[
                    {
                      icon: 'add',
                      tooltip: 'Создать настройки',
                      isFreeAction: true,
                      onClick: () => {
                        setSelectedVariable(null);
                        setOpenCreateOrUpdateModal(true);
                      },
                    },
                    {
                      icon: 'edit',
                      tooltip: 'Обновить настройки',
                      onClick: (_, rowData) => {
                        setSelectedVariable(rowData);
                        setOpenCreateOrUpdateModal(true);
                      },
                    },
                  ]}
                  localization={{
                    body: {
                      editTooltip: 'Редактировать',
                      deleteTooltip: 'Удалить',
                      editRow: {
                        deleteText: 'Вы уверены что хотите удалить настройку?',
                      },
                    },
                    header: {
                      actions: 'Действия',
                    },
                  }}
                  columns={[
                    {
                      title: 'Название',
                      field: 'name',
                      defaultSort: 'asc',
                      validate: (rowData) =>
                        validateField(nameSchema, {
                          name: rowData.name,
                        }),

                      render: (rowData) => variableNames[rowData.name] || rowData.name,
                    },
                    {
                      title: 'Значение',
                      field: 'value',
                      sorting: false,
                      validate: (rowData) =>
                        validateField(valueSchema, {
                          value: rowData.value,
                        }),
                    },
                  ]}
                  // @ts-ignore
                  data={variableList}
                  options={{
                    filtering: false,
                  }}
                  editable={{
                    isEditable: () => true,
                    onRowAddCancelled: () => console.log('Row adding cancelled'),
                    onRowUpdateCancelled: () => console.log('Row editing cancelled'),
                    onRowDelete,
                  }}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
