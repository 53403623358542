import { makeStyles } from '@material-ui/core/styles';

export const dialogStyles = makeStyles(() => ({
  dialog: {
    zIndex: 10000,
  },
  paper: {
    maxWidth: 500,
    width: '100%',
  },
  form: {
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
  selectFormControl: {
    margin: '10px 0 4px',
  },
  errorText: {
    color: 'red',
    fontSize: 12,
  },
  colorWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridRowGap: '10px',
  },
  colorTextField: {
    '& input': {
      minHeight: 50,
    },
  },
  colorPreview: {
    width: 50,
    height: 50,
    justifySelf: 'center',
    alignSelf: 'center',
  },
  imageWrapper: {
    width: '100%',
    paddingBottom: '100%',
    position: 'relative',
  },
  imageBlock: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  imageTag: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
}));
