import axios from 'axios';
import { useAuth } from './use-auth';

export const useAuthAPI = () => {
  const auth = useAuth();

  return (endpoint, data) => {
    return axios.get(endpoint, {
      headers: { Authorization: `Bearer ${auth.token}` },
      params: data,
    });
  };
};
