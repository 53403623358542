export function helper() {
  var helper = {
    letterToName: function (char) {
      if (char === 'p') {
        return 'pawn'
      } else if (char === 'n') {
        return 'knight'
      } else if (char === 'b') {
        return 'bishop'
      } else if (char === 'q') {
        return 'queen'
      } else if (char === 'k') {
        return 'king'
      } else if (char === 'r') {
        return 'rook'
      }

      return 'other'
    },
    nameToLetter: function (word, color) {
      if (!word) {
        return ''
      }

      color = color.substr(0, 1)

      var clr = !color ? 'b' : color
      var char = ''

      if (word === 'pawn') {
        char = 'p'
      } else if (word === 'knight') {
        char = 'n'
      } else if (word === 'bishop') {
        char = 'b'
      } else if (word === 'queen') {
        char = 'q'
      } else if (word === 'king') {
        char = 'k'
      } else if (word === 'rook') {
        char = 'r'
      }

      if (clr === 'w') {
        return char.toUpperCase()
      }

      return char
    },
    numberToLetter: function (num) {
      if (num === 1) {
        return 'p'
      } else if (num === 2) {
        return 'n'
      } else if (num === 3) {
        return 'b'
      } else if (num === 4) {
        return 'r'
      } else if (num === 5) {
        return 'q'
      } else if (num === 6) {
        return 'k'
      }

      return 'other'
    },
    letterToNumber: function (figure) {
      var char = figure.toLowerCase()

      if (char === 'p') {
        return 1
      } else if (char === 'n') {
        return 2
      } else if (char === 'b') {
        return 3
      } else if (char === 'q') {
        return 5
      } else if (char === 'k') {
        return 6
      } else if (char === 'r') {
        return 4
      }

      return 'other'
    },
    inverseColor: function (color) {
      color = helper.colorCodeToWord(color)
      if (color === 'white') {
        return 'black'
      }

      return 'white'
    },
    rowToLetter: function (row) {
      return String.fromCharCode(96 + row)
    },
    colorCodeToWord: function (code) {
      var char = code.substring(0, 1)
      if (char === 'w') {
        return 'white'
      } else if (char === 'b') {
        return 'black'
      }

      return 'none'
    },
    getPieceColor: function (piece) {
      var color = 'black'
      if (piece === piece.toUpperCase()) {
        color = 'white'
      }

      return color
    },
    diceArrayToObject: function (dice, active, status) {
      active = !!active
      var response = []
      for (var i = 0; i < dice.length; i++) {
        response[i] = {
          active: active,
          figure: dice[i],
          status: active ? 'active' : status || 'inactive'
        }
      }

      return response
    },
    bindLegalDice: function (dice, legal) {
      var legalNumbers = []
      for (var j = 0; j < legal.length; j++) {
        legalNumbers.push(helper.letterToNumber(legal[j]))
      }

      var num, index
      for (var i = 0; i < 3; i++) {
        num = dice[i].dice ? dice[i].dice : dice[i].figure

        index = legalNumbers.indexOf(num)
        if (index === -1) {
          dice[i].active = false
        } else {
          legalNumbers.splice(index, 1)
        }
      }

      return dice
    },
    diceAreOkay: function (dice) {
      if (dice.length === 0) {
        return false
      }

      var sum = 0
      for (var i = 0; i < 3; i++) {
        sum += dice[i].dice ? dice[i].dice : dice[i].figure
      }

      if (sum < 3) {
        return false
      }

      return true
    },
    allDiceAreActive: function (dice) {
      if (dice.length < 3) {
        return false
      }

      var activeCount = 0
      for (var i = 0; i < 3; i++) {
        activeCount += dice[i].active ? 1 : 0
      }

      if (activeCount === 3) {
        return true
      }

      return false
    },
    isPlayer: function (color) {
      if (
        helper.colorCodeToWord(color) === 'white' ||
        helper.colorCodeToWord(color) === 'black'
      ) {
        return true
      }

      return false
    }
  }

  return helper
}
