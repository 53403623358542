import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function W5({ width, height }) {
  return (
    <SVGUniqueID>
      <svg
        id='w-05_svg__Layer_1'
        x={0}
        y={0}
        viewBox='0 0 70 70'
        xmlSpace='preserve'
        width={width}
        height={height}
      >
        <style />
        <linearGradient
          id='w-05_svg__SVGID_1_'
          gradientUnits='userSpaceOnUse'
          x1={35}
          y1={69.5}
          x2={35}
          y2={0.5}
        >
          <stop offset={0} stopColor='#bcc5c7' />
          <stop offset={0.65} stopColor='#e6e8e9' />
        </linearGradient>
        <path
          d='M10 69.5C4.8 69.5.5 65.2.5 60V10C.5 4.8 4.8.5 10 .5h50c5.2 0 9.5 4.3 9.5 9.5v50c0 5.2-4.3 9.5-9.5 9.5H10z'
          fill='url(#w-05_svg__SVGID_1_)'
        />
        <path
          d='M60 1c5 0 9 4 9 9v50c0 5-4 9-9 9H10c-5 0-9-4-9-9V10c0-5 4-9 9-9h50m0-1H10C4.5 0 0 4.5 0 10v50c0 5.5 4.5 10 10 10h50c5.5 0 10-4.5 10-10V10c0-5.5-4.5-10-10-10z'
          fill='#fff'
        />
        <g>
          <linearGradient
            id='w-05_svg__SVGID_2_'
            gradientUnits='userSpaceOnUse'
            x1={11.383}
            y1={35.733}
            x2={58.618}
            y2={35.733}
          >
            <stop offset={0} stopColor='#f7f5d2' />
            <stop offset={1} stopColor='#d7d4b7' />
          </linearGradient>
          <path
            d='M35 58.7c-10.2 0-15.5-1.9-15.5-2.7v-.2c.6-2.4.6-2.7.6-6.6 0-4.4-4.7-6.9-4.9-7-2.6-1.6-3.8-4.5-3.8-8.9 0-5.8 4.7-10.5 10.5-10.5 3.1 0 6 1.3 8 3.7l1.4 1.7-.3-2.1v-.5c0-2 1.5-3.7 3.5-4l.6-.1v-5.4h-3.4H35v-3.4 3.4h3.4H35v5.4l.6.1c2 .3 3.5 2 3.5 4v.5l-.2 2.2 1.4-1.7c2-2.3 4.9-3.7 8-3.7 5.8 0 10.5 4.7 10.5 10.5 0 3.7-1.9 7-5 8.9-.2.1-3.8 2.6-3.8 6.9 0 3.9 0 4.1.6 6.6v.2c-.1.8-5.4 2.7-15.6 2.7z'
            fill='url(#w-05_svg__SVGID_2_)'
          />
          <linearGradient
            id='w-05_svg__SVGID_3_'
            gradientUnits='userSpaceOnUse'
            x1={35.001}
            y1={61.175}
            x2={35.001}
            y2={12.789}
          >
            <stop offset={0.001} stopColor='#fff' />
            <stop offset={1} stopColor='#a4b0b2' />
          </linearGradient>
          <path
            d='M35 12.8v8.8l.6.1c2 .3 3.5 2 3.5 4v.5l-.2 2.2 1.4-1.7c2-2.3 4.9-3.7 8-3.7 5.8 0 10.5 4.7 10.5 10.5 0 3.7-1.9 7-5 8.9-.2.1-3.8 2.6-3.8 6.9 0 3.9 0 4.1.6 6.6v.2c0 .9-5.3 2.7-15.5 2.7s-15.5-1.9-15.5-2.7v-.2c.6-2.4.6-2.7.6-6.6 0-4.4-4.7-6.9-4.9-7-2.6-1.6-3.8-4.5-3.8-8.9 0-5.8 4.7-10.5 10.5-10.5 3.1 0 6 1.3 8 3.7l1.4 1.7-.4-2.2v-.5c0-2 1.5-3.7 3.5-4l.6-.1v-5.4h-3.4H35v-3.3m3.4 3.4H35h3.4m-5.9-3.4v.9h-.9v5h.9v.9c-1.5.6-2.6 1.7-3.3 3.1-2.1-1.5-4.7-2.3-7.3-2.3-7.1 0-13 5.8-13 13 0 5.3 1.7 9 5 11l.1.1h.1s3.6 1.9 3.6 4.7c0 3.7 0 3.8-.5 5.8l-.2.4v.6c0 2.5 2.8 3.4 5.9 4.1 2.3.5 6.2 1.1 12.1 1.1 8.4 0 15.3-1.3 17.1-3.2.6-.6.9-1.3.9-2.1v-.6l-.2-.3c-.5-1.9-.5-2.1-.5-5.8 0-2.9 2.3-4.6 2.6-4.8 3.9-2.4 6.1-6.5 6.1-11 0-7.1-5.8-13-13-13-2.6 0-5.2.8-7.3 2.3-.7-1.4-1.9-2.5-3.3-3.1v-.9h.9v-5h-.9v-.9h-4.9z'
            fill='url(#w-05_svg__SVGID_3_)'
          />
          <path
            d='M48.2 22.2c-3.4 0-6.4 1.5-8.5 3.9v-.5c0-2.4-1.8-4.3-4-4.7v-4.1H39v-1.3h-3.4v-3.4h-1.3v3.4H31v1.3h3.4v4.1c-2.3.3-4 2.3-4 4.7v.5c-2-2.4-5.1-3.9-8.5-3.9-6.1 0-11.1 5-11.1 11.1 0 4 1 7.5 4.2 9.5 0 0 4.6 2.3 4.6 6.4 0 3.9 0 4-.6 6.4-.1.1-.1.2-.1.4 0 1.9 7.3 3.4 16.2 3.4s16.2-1.5 16.2-3.4c0-.1 0-.2-.1-.4-.6-2.4-.6-2.5-.6-6.4 0-4 3.4-6.4 3.4-6.4 3.2-2 5.3-5.5 5.3-9.5 0-6.1-5-11.1-11.1-11.1zm-13.7.1l.5-.1.5.1c1.6.2 2.9 1.7 2.9 3.3v.4l-.2 2c-.5.5-2.3 2-3.1 4.7-.9-2.7-2.6-4.2-3.1-4.7l-.2-2v-.4c-.2-1.7 1.1-3.1 2.7-3.3zM20.8 50.6c1.8-.8 6.6-1.8 14.2-1.8 7.5 0 12.3 1 14.1 1.8 0 1.6 0 2.5.2 3.4-2.7-1.1-8.1-1.8-14.3-1.8-6.3 0-11.7.7-14.4 1.8.2-.9.2-1.8.2-3.4zm28.4-1.5c-2.8-1-8-1.7-14.1-1.7s-11.5.7-14.2 1.8c0-1.1-.3-2.1-.7-3h.2c.9-.9 6-2.2 14.6-2.2s13.6 1.3 14.7 2.1c-.3.9-.5 1.9-.5 3zm-33.6-7.4c-2.4-1.5-3.5-4.2-3.5-8.3 0-5.4 4.4-9.8 9.8-9.8 2.9 0 5.6 1.3 7.4 3.4l2.8 3.3c.9 1.1 1.9 3 1.9 5.6v6.7c-7 .1-12.8 1.1-14.6 2.5-1.4-2-3.4-3.2-3.8-3.4zM35 58c-8.6 0-13.7-1.4-14.8-2.2 0-.1 0-.1.1-.2 1.1-.8 6.2-2.2 14.7-2.2s13.5 1.3 14.7 2.1c0 .1 0 .2.1.2-1.1.9-6.2 2.3-14.8 2.3zm18.3-16.3c-.2.1-1.8 1.2-2.9 3.2-2.1-1.3-7.7-2.2-14.4-2.3v-6.7c0-2.6 1.1-4.5 2-5.6l2.8-3.2c1.9-2.2 4.6-3.4 7.4-3.4 5.4 0 9.8 4.4 9.8 9.8-.1 3.3-1.8 6.4-4.7 8.2z'
            fill='#231f20'
          />
        </g>
      </svg>
    </SVGUniqueID>
  )
}
