import React, { useEffect, useState } from 'react';
import { Box, Divider, List, Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';
import { BeatLoader } from 'react-spinners';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TreeItem from '@material-ui/lab/TreeItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { Delete, Add as Create, AccountTree } from '@material-ui/icons';

import endpoints from 'api/endpoints';
import { isDevClient } from 'constants/isDevClient';
import { errorService, infoService } from 'services/alert/services';
import { LanguageService } from 'services/api/language';
import { useAPI } from 'hooks/use-api';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import Card from 'components/Card/Card.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import ConfirmDialog from 'components/ConfirmDialog';

import CustomInput from '../../components/CustomInput/CustomInput';
import CardFooter from '../../components/Card/CardFooter';

const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  tabPane: {
    flexGrow: 2,
  },
  item: {
    '& .MuiTreeItem-label': {
      padding: '7px 10px',
    },
  },
  divider: {
    margin: '10px 0',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  search: {
    color: '#fff',
    margin: 0,
    padding: 0,
    marginTop: -20,
  },
  searchInput: {
    color: '#fff',
  },
}));

const clone = (obj) => JSON.parse(JSON.stringify(obj));

export default function Languages() {
  const [{ data, isDataLoading, isError }, doGetLanguages] = useAPI();
  const [{ data: dataPost, isError: isErrorPost }, doSaveLang] = useAPI({ type: 'post' });
  const [{ data: dataDelete, isError: isErrorDelete }, doDelete] = useAPI({ type: 'delete' });

  const [languages, setLanguages] = useState([]);
  const [editedLanguages, setEditedLanguages] = useState([]);
  const [selected, setSelected] = useState(0);
  const [addNewLangModalOpen, setAddNewLangModalOpen] = useState(false);
  const [newLangName, setNewLangName] = useState('');
  const [newLangKey, setNewLangKey] = useState('');
  const [searched, setSearched] = useState('');
  const [expanded, setExpanded] = useState([]);
  const [exportIsLoading, setExportLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    doGetLanguages(endpoints.languages);
  }, []);

  useEffect(() => {
    doGetLanguages(endpoints.languages);
    doDelete();
  }, [dataDelete, isErrorDelete]);

  useEffect(() => {
    setEditedLanguages(clone(languages));
  }, [languages]);

  useEffect(() => {
    if (selected >= editedLanguages.length) setSelected(0);
  }, [editedLanguages.length]);

  useEffect(() => {
    if (isError) alert('Error API' + data);
  }, [isError]);

  useEffect(() => {
    if (!data || isError) return;

    setLanguages(data.languages);
    doGetLanguages(endpoints.languages);
  }, [data]);

  useEffect(() => {
    if ((selected && selected < 0) || !searched || searched.length < 3) {
      setExpanded([]);
    } else {
      const lang = editedLanguages[selected];
      const contains = (scope, before = [], prop = '') => {
        if ('object' === typeof scope && scope !== null) {
          const nested = Object.entries(scope)
            .map(([key, val]) => contains(val, before, prop ? `${prop}.${key}` : key))
            .reduce((all, currents) => [...all, ...currents]);

          if (nested.length) {
            return [...before, prop, ...nested];
          }

          return before;
        }

        if (new RegExp(searched, 'i').test(`${scope}`)) {
          return [...before, prop];
        }

        return before;
      };

      const expanded = contains(lang.data);

      setExpanded(expanded);
    }
  }, [selected, searched]);

  const classes = styles();

  const closeNewModal = () => {
    setAddNewLangModalOpen(false);
    setNewLangKey('');
    setNewLangName('');
  };

  const createNewLang = () => {
    if (!newLangName || !newLangKey) {
      return;
    }

    let toCopyIdx = editedLanguages.findIndex(({ language }) => language === 'en');

    if (toCopyIdx === -1) {
      toCopyIdx = 0;
    }

    const currentlength = editedLanguages.length;
    const source = currentlength ? editedLanguages[toCopyIdx] : {};

    setEditedLanguages((languages) => [
      ...languages,
      {
        language: newLangKey.toLowerCase(),
        name: newLangName,
        data: clone(source.data || {}),
      },
    ]);

    setSelected(currentlength);

    closeNewModal();
  };

  const applyChanges = async () => {
    const lang = editedLanguages[selected];

    setIsLoading(true)
    try {
      await LanguageService.updateLocale(lang)
    } catch(e) {
      errorService.sendError(e.message)
    }
    setIsLoading(false)
  };

  const exportTranslations = async () => {
    const language = languages[selected]?.language;
    setExportLoading(true);
    if (!language) {
      errorService.sendError('Язык не выбран');
      return;
    }
    try {
      const { data } = await LanguageService.exportTranslations(language);
      if (data.error) {
        errorService.sendError(`Произошла какая то ошибка`);
      } else {
        infoService.sendInfo('Переводы успешно экспортированы');
      }
    } catch (e) {
      errorService.sendError(`Произошла какая то ошибка: ${e.message}`);
    } finally {
      setExportLoading(false);
    }
  };

  const lastExportDate = data?.languages?.[selected]?.lastExportDate;

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" className={classes.header}>
              <p className={classes.cardTitleWhite}>Языки </p>
              <CustomInput
                formControlProps={{
                  className: classes.search,
                }}
                inputProps={{
                  className: classes.searchInput,
                  placeholder: 'Search',
                  inputProps: {
                    'aria-label': 'Search',
                    value: searched,
                    onChange: (e) => setSearched(e.target.value),
                  },
                }}
              />
            </CardHeader>
            <CardBody>
              {(isDataLoading || isLoading) ? (
                <Box textAlign="center">
                  <BeatLoader size={16} color="#00acc1" loading={true} />
                </Box>
              ) : (
                <div className={classes.root}>
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={selected}
                    onChange={(e, idx) => {
                      setSelected(idx);
                    }}
                    aria-label="Vertical tabs example"
                    className={classes.tabs}
                  >
                    {editedLanguages.map(({ name, language }) => (
                      <Tab label={name} id={language} />
                    ))}
                  </Tabs>
                  {editedLanguages.map(({ name, data, language }, index) => (
                    <LangData
                      index={index}
                      key={index}
                      active={selected === index}
                      data={data}
                      language={language}
                      name={name}
                      toExpand={expanded}
                      onEdit={(name, data) =>
                        setEditedLanguages((languages) => {
                          return languages.map((lang, i) => {
                            if (i !== index) return lang;

                            return {
                              ...lang,
                              name,
                              data,
                            };
                          });
                        })
                      }
                      onDelete={(language) => doDelete(`${endpoints.languages}/${language}`)}
                    />
                  ))}
                </div>
              )}
            </CardBody>
            <CardFooter>
              <div>
                <div>
                  {isDevClient && (
                    <Button
                      edge="end"
                      variant="outlined"
                      color="primary"
                      disabled={exportIsLoading}
                      onClick={exportTranslations}
                    >
                      Export
                    </Button>
                  )}
                  <Button
                    edge="end"
                    variant="text"
                    color="default"
                    onClick={() => {
                      setEditedLanguages(clone(languages));
                    }}
                  >
                    Reset
                  </Button>
                </div>
                {isDevClient && lastExportDate && (
                  <span>
                    Последнее обновление:{' '}
                    {moment.tz(lastExportDate, moment.tz.guess()).format('HH:mm DD-MM-YYYY')}
                  </span>
                )}
              </div>
              <Button edge="end" variant="outlined" color="primary" onClick={() => applyChanges()}>
                Save
              </Button>
              <IconButton edge="end" color="primary" onClick={() => setAddNewLangModalOpen(true)}>
                <Icon style={{ fontSize: 30 }}>add_circle</Icon>
              </IconButton>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <Dialog
        open={addNewLangModalOpen}
        onClose={closeNewModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit the message</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Language Name"
            value={newLangName}
            onChange={(event) => setNewLangName(event.target.value)}
            onKeyPress={(event) => {
              if (event.key.toLowerCase() === 'enter') {
                createNewLang();
              }
            }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="name"
            label="Language Key"
            value={newLangKey}
            onChange={(event) => setNewLangKey(event.target.value)}
            onKeyPress={(event) => {
              if (event.key.toLowerCase() === 'enter') {
                createNewLang();
              }
            }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeNewModal} color="default">
            Cancel
          </Button>
          <Button onClick={createNewLang} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const LangData = ({ active, index, name, data, language, onEdit, onDelete, toExpand }) => {
  const classes = styles();
  const [editName, setEditName] = useState(false);
  const [deleteLang, setDeleteLang] = useState(false);
  const [expanded, setExpanded] = useState([]);
  const [newName, setNewName] = useState('');

  const parseAction = (prop) => {
    const [toEdit, ...path] = prop.split('.').reverse();

    return [toEdit, path.reverse().reduce((d, prop) => d[prop], data)];
  };

  const onEditProp = (path, value) => {
    const [prop, propHolder] = parseAction(path);

    propHolder[prop] = value;

    onEdit(name, data);
  };

  const onDeleteProp = (path) => {
    const [prop, propHolder] = parseAction(path);
    delete propHolder[prop];

    onEdit(name, data);
  };

  return (
    <div
      className={classes.tabPane}
      role="tabpanel"
      hidden={!active}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {active && (
        <Box p={3}>
          <div style={{ display: 'flex' }}>
            <Typography variant="h5" style={{ flexGrow: 3 }}>
              {name}
            </Typography>
            <IconButton edge="end" aria-label="edit" onClick={() => setEditName(true)}>
              <EditIcon />
            </IconButton>
            <IconButton edge="end" aria-label="delete" onClick={() => setDeleteLang(true)}>
              <DeleteIcon />
            </IconButton>
            <Dialog
              open={editName}
              onClose={() => {
                setEditName(false);
                setNewName('');
              }}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Edit the Language Name</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label={name}
                  value={newName}
                  onChange={(event) => setNewName(event.target.value)}
                  onKeyPress={(event) => {
                    if (event.key.toLowerCase() === 'enter') {
                      setEditName(false);
                      onEdit(newName, data);
                      setNewName('');
                    }
                  }}
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setEditName(false);
                    setNewName('');
                  }}
                  color="default"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setEditName(false);
                    onEdit(newName, data);
                    setNewName('');
                  }}
                  color="primary"
                >
                  Edit
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={deleteLang}
              onClose={() => setDeleteLang(false)}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Do You want to delete this Language</DialogTitle>
              <DialogActions>
                <Button onClick={() => setDeleteLang(false)} color="default">
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setDeleteLang(false);
                    onDelete(language);
                  }}
                  color="secondary"
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <Divider className={classes.divider} />
          <TreeView
            className={classes.tree}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpanded={['root']}
            defaultExpandIcon={<ChevronRightIcon />}
            selected={toExpand}
            multiSelect
            expanded={[...expanded, ...(toExpand || [])]}
            onNodeToggle={(e, nodes) => setExpanded(nodes)}
          >
            {Object.entries(data).map(([prop, value]) => (
              <Tree
                data={value}
                itemKey={prop}
                key={prop}
                name={prop}
                onEdit={onEditProp}
                onDelete={onDeleteProp}
                toExpand={toExpand}
              />
            ))}
          </TreeView>
        </Box>
      )}
    </div>
  );
};

const Tree = ({ data, itemKey, name, onEdit, onDelete, toExpand }) => {
  const classes = styles();
  const isLeaf = !(typeof data === 'object' && data !== null);
  const styledName = (name) =>
    name
      .replace(/([A-Z]+)/g, '(_)(_)$1')
      .split('(_)(_)')
      .map(([first = '', ...rest]) => `${first.toUpperCase()}${rest.join('').toLowerCase()}`)
      .join(' ');

  const Component = isLeaf ? TreeLeaf : TreeParent;

  return (
    <Component
      data={data}
      itemKey={itemKey}
      name={styledName(name)}
      className={classes.item}
      onEdit={onEdit}
      onDelete={onDelete}
      toExpand={toExpand}
    />
  );
};

const TreeParent = ({ data, itemKey, name, className, onEdit, onDelete, toExpand }) => {
  const [expanded, setExpanded] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [mode, setMode] = useState('item'); // group or item
  const [newKey, setNewKey] = useState('');
  const [value, setValue] = useState('');

  useEffect(() => {
    setNewKey('');
    setValue('');
  }, [isOpen]);

  const label = (
    <List style={{ padding: 0 }}>
      <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
        <ListItemText primary={name} style={{ padding: 0 }} />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="createGroup"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setIsOpen(true);
              setMode('group');
            }}
          >
            <AccountTree />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="create"
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(true);
              setMode('item');
              e.preventDefault();
            }}
          >
            <Create />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="create"
            onClick={(e) => {
              e.stopPropagation();
              setIsConfirmDialogOpen(true);
              e.preventDefault();
            }}
          >
            <Delete />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Create new locale</DialogTitle>
        <DialogContent>
          {!Array.isArray(data) && (
            <TextField
              autoFocus
              multiline
              margin="dense"
              id="key"
              label={'Key'}
              value={newKey}
              onChange={(event) => setNewKey(event.target.value)}
              fullWidth
              size="medium"
            />
          )}
          {mode === 'item' && (
            <TextField
              multiline
              margin="dense"
              id="value"
              label={'Value'}
              value={value}
              onChange={(event) => setValue(event.target.value)}
              fullWidth
              size="medium"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              setIsOpen(false);
              e.stopPropagation();
            }}
            color="default"
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              setIsOpen(false);
              e.stopPropagation();

              if (mode === 'group') {
                onEdit(itemKey, {
                  ...data,
                  [newKey]: {},
                });
              } else if (mode === 'item') {
                if (Array.isArray(data)) {
                  onEdit(itemKey, [...data, value]);
                  return;
                }
                onEdit(itemKey, {
                  ...data,
                  [newKey]: value,
                });
              }
            }}
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        title="Вы уверены что хотите удалить перевод?"
        cancelText="Отмена"
        approveText="Удалить"
        open={isConfirmDialogOpen}
        onClose={() => {
          setIsConfirmDialogOpen(false);
        }}
        onCancel={() => {
          setIsConfirmDialogOpen(false);
        }}
        onApprove={() => {
          onDelete(itemKey);
        }}
      />
    </List>
  );

  return (
    <TreeItem
      className={className}
      key={itemKey}
      nodeId={itemKey}
      label={label}
      selected={toExpand}
      multiselect
      expanded={[...expanded, ...(toExpand || [])]}
      onNodeToggle={(e, nodes) => setExpanded(nodes)}
    >
      {Object.entries(data).map(([prop, value]) => (
        <Tree
          data={value}
          itemKey={`${itemKey}.${prop}`}
          key={`${itemKey}.${prop}`}
          name={prop}
          onEdit={onEdit}
          onDelete={onDelete}
          toExpand={toExpand}
        />
      ))}
    </TreeItem>
  );
};

const TreeLeaf = ({ data, itemKey, name, className, onEdit, onDelete }) => {
  const [newData, setNewData] = useState(data);
  const [edit, setEdit] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  useEffect(() => {
    setNewData(data);
  }, [data]);

  return (
    <TreeItem
      className={className}
      key={itemKey}
      nodeId={itemKey}
      label={
        <List style={{ padding: 0 }}>
          <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
            <ListItemText primary={name} secondary={data} style={{ padding: 0 }} />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="edit" onClick={() => setEdit(true)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="edit" onClick={() => setIsConfirmDialogOpen(true)}>
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Dialog
            open={edit}
            onClose={() => {
              setEdit(false);
              setNewData(data);
            }}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
            fullWidth
          >
            <DialogTitle id="form-dialog-title">Edit the message</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                multiline
                margin="dense"
                id="name"
                label={name}
                value={newData}
                onChange={(event) => setNewData(event.target.value)}
                onKeyPress={(event) => {
                  if (event.key.toLowerCase() === 'enter') {
                    setEdit(false);
                    onEdit(itemKey, newData);
                    setNewData(data);
                  }
                }}
                fullWidth
                size="medium"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setEdit(false);
                  setNewData(data);
                }}
                color="default"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setEdit(false);
                  onEdit(itemKey, newData);
                  setNewData(data);
                }}
                color="primary"
              >
                Edit
              </Button>
            </DialogActions>
          </Dialog>
          <ConfirmDialog
            title="Вы уверены что хотите удалить перевод?"
            cancelText="Отмена"
            approveText="Удалить"
            open={isConfirmDialogOpen}
            onClose={() => {
              setIsConfirmDialogOpen(false);
            }}
            onCancel={() => {
              setIsConfirmDialogOpen(false);
            }}
            onApprove={() => {
              onDelete(itemKey);
            }}
          />
        </List>
      }
    />
  );
};
