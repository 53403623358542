import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useStyles } from './styles';

const swiperBreakpoints = {
  0: {
    slidesPerView: 1,
  },
  400: {
    slidesPerView: 2,
  },
  600: {
    slidesPerView: 4,
  },
  1000: {
    slidesPerView: 6,
  },
};

const ImagesPreview = ({ images, onClose, onImageDelete }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <CloseIcon className={classes.closeIcon} onClick={onClose} />
      <Swiper
        spaceBetween={20}
        slidesPerView={4}
        breakpoints={swiperBreakpoints}
        className={classes.slider}
      >
        {images.map((img, i) => (
          <SwiperSlide key={i} className={classes.sliderItem}>
            <div className={classes.imageBox}>
              <img src={img} className={classes.image} />
              <DeleteIcon className={classes.deleteImageIcon} onClick={() => onImageDelete(i)} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ImagesPreview;
