import endpoints from 'api/endpoints';
import { api } from 'api/rest';

export class ChatService {
  static async sendMessage({ chat, content }) {
    const response = await api.post(endpoints.supportChats, { chat, content });
    return response;
  }
  static async sendFilesMessage(formData) {
    const response = await api.post(endpoints.supportChats, formData);
    return response;
  }
  static async updateMessage({ messageId, content }) {
    const response = await api.put(`${endpoints.supportMessage}/${messageId}`, {
      content,
    });
    return response;
  }
  static async deleteMessage(messageId) {
    const response = await api.delete(`${endpoints.supportMessage}/${messageId}`);
    return response;
  }
  static createSupportChat(userId) {
    return api.post(endpoints.createSupportChat, { user: userId });
  }
}
