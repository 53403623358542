import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    maxWidth: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
  },
  title: {
    margin: 0,
    fontSize: 28,
  },
  messagesScroll: {
    height: 'calc(100vh - 100px)',
    overflowY: 'auto',
    margin: '20px 0',
    padding: '10px',
    position: 'relative',
  },
  signal: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 20,
    height: 20,
  },
  messagesContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
});
