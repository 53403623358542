import React, { useMemo, useState } from 'react';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { Select } from '@material-ui/core';
import { MenuItem, Typography } from '@material-ui/core';
import cn from 'classnames';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';

import { dialogStyles } from './styles';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { bannerTypes, redirectTypes, styleTypes } from './constants';
import UploadImage from './components/UploadImage';
import { errorService } from 'services/alert/services';
import { FileService } from '../../services/api/file';
import { mapBannerType, mapRedirectType, mapStyleType } from './utils';

const initialImageValue = {
  url: '',
  filename: '',
};

const CreateOrUpdateBannerModal = ({ open, handleClose, onCreate, onUpdate, mode, banner }) => {
  const styles = dialogStyles();
  const [isLoading, setLoading] = useState(false);

  const submitHandler = async (values, actions) => {
    setLoading(true);
    if (mode === 'create') await onCreate(values, actions);
    else await onUpdate(values, banner, actions);
    setLoading(false);
  };

  const onUpload = async (files, image, setFieldValue, device) => {
    try {
      const formData = new FormData();
      Array.from(files).forEach((file) => {
        formData.append('files', file);
      });
      const { data } = await FileService.uploadFiles(formData);
      setFieldValue('image', {
        ...image,
        [device]: {
          filename: data.url,
          url: null,
        },
      });
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  const initialValues = useMemo(() => {
    if (mode === 'create') {
      return {
        text: '',
        type: bannerTypes.stream,
        backgroundColor: '',
        image: {
          desktop: {
            url: null,
            filename: null,
          },
          mobile: {
            url: null,
            filename: null,
          },
        },
        date: '',
        redirectType: redirectTypes.external_redirect,
        redirectTo: '',
        button: {
          backgroundColor: '',
          borderColor: '',
        },
        styleType: styleTypes.withAvatar,
        enabled: true,
        order: 1,
      };
    }
    return {
      text: banner.text,
      type: banner.type,
      backgroundColor: banner.backgroundColor,
      image: banner.image,
      date: banner.date,
      redirectType: banner.redirectType,
      redirectTo: banner.redirectTo,
      button: banner.button,
      styleType: banner.styleType,
      enabled: banner.enabled,
      order: banner.order,
    };
  }, [banner, mode]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className={styles.dialog}
        PaperProps={{ className: styles.paper }}
      >
        <DialogTitle id="form-dialog-title">
          {mode === 'update' ? 'Обновить' : 'Создать'} баннер
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({})}
            onSubmit={submitHandler}
            validateOnChange
            render={({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              touched,
              values,
              errors,
            }) => {
              return (
                <form onSubmit={handleSubmit} className={styles.form}>
                  {values.type === bannerTypes.stream && (
                    <FormControl className={styles.formControl} size="small">
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.text && touched.text)}
                        name="text"
                        margin="dense"
                        label="Текст"
                        value={values.text}
                        placeholder="Введите текст"
                      />
                      <div className={styles.errorText}>{touched.text ? errors.text : ''}</div>
                    </FormControl>
                  )}
                  <FormControl
                    className={cn(styles.formControl, styles.selectFormControl)}
                    size="small"
                  >
                    <Select
                      value={values.type}
                      label="Тип действия"
                      name="type"
                      onChange={handleChange}
                      displayEmpty
                    >
                      {Object.entries(bannerTypes).map(([_, clickTypeValue]) => (
                        <MenuItem key={clickTypeValue} value={clickTypeValue}>
                          {mapBannerType(clickTypeValue)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    className={cn(styles.formControl, styles.selectFormControl)}
                    size="small"
                  >
                    <Select
                      value={values.redirectType}
                      label="Вид редиректа"
                      name="redirectType"
                      onChange={handleChange}
                      displayEmpty
                    >
                      {Object.entries(redirectTypes).map(([_, clickTypeValue]) => (
                        <MenuItem key={clickTypeValue} value={clickTypeValue}>
                          {mapRedirectType(clickTypeValue)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className={styles.formControl} size="small">
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.redirectTo && touched.redirectTo)}
                      name="redirectTo"
                      margin="dense"
                      label="Перенаправление"
                      value={values.redirectTo}
                      placeholder="Перенаправление в"
                    />
                    <div className={styles.errorText}>
                      {touched.redirectTo ? errors.redirectTo : ''}
                    </div>
                  </FormControl>
                  {values.type === bannerTypes.stream && (
                    <FormControl
                      className={cn(styles.formControl, styles.selectFormControl)}
                      size="small"
                    >
                      <Select
                        value={values.styleType}
                        label="Стиль"
                        name="styleType"
                        onChange={handleChange}
                        displayEmpty
                      >
                        {Object.entries(styleTypes).map(([_, styleType]) => (
                          <MenuItem key={styleType} value={styleType}>
                            {mapStyleType(styleType)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {values.type === bannerTypes.stream && (
                    <FormControl className={styles.formControl} size="small">
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.backgroundColor && touched.backgroundColor)}
                        name="backgroundColor"
                        margin="dense"
                        label="Цвет фона"
                        value={values.backgroundColor}
                        placeholder="Цвет фона"
                      />
                      <div className={styles.errorText}>
                        {touched.backgroundColor ? errors.backgroundColor : ''}
                      </div>
                    </FormControl>
                  )}
                  {values.type === bannerTypes.stream && (
                    <>
                      <FormControl className={styles.formControl} size="small">
                        <TextField
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            !!(errors.button?.backgroundColor && touched.button?.backgroundColor)
                          }
                          name="button.backgroundColor"
                          margin="dense"
                          label="Цвет фона(кнопка)"
                          value={values.button?.backgroundColor}
                          placeholder="Цвет фона(кнопка)"
                        />
                        <div className={styles.errorText}>
                          {touched.button?.backgroundColor ? errors.button?.backgroundColor : ''}
                        </div>
                      </FormControl>
                      <FormControl className={styles.formControl} size="small">
                        <TextField
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!(errors.button?.borderColor && touched.button?.borderColor)}
                          name="button.borderColor"
                          margin="dense"
                          label="Цвет границы кнопки"
                          value={values.button?.borderColor}
                          placeholder="Цвет границы кнопки"
                        />
                        <div className={styles.errorText}>
                          {touched.button?.borderColor ? errors.button?.borderColor : ''}
                        </div>
                      </FormControl>
                    </>
                  )}
                  <FormControl className={styles.formControl} size="small">
                    {!values.image?.desktop?.filename && (
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.image?.desktop?.url && touched.image?.desktop?.url)}
                        name="image.desktop.url"
                        margin="dense"
                        label="Ссылка на десктоп изображение"
                        value={values.image?.desktop?.url}
                        placeholder="Ссылка на десктоп изображение"
                      />
                    )}
                    <div className={styles.errorText}>
                      {touched.image?.desktop?.url || touched.image?.desktop?.filename}
                    </div>
                    {!values.image.url && (
                      <UploadImage
                        onUpload={(files) =>
                          onUpload(files, values.image, setFieldValue, 'desktop')
                        }
                        image={values.image.desktop}
                        onImageRemove={() =>
                          setFieldValue('image', {
                            mobile: values.image.mobile,
                            desktop: { ...initialImageValue },
                          })
                        }
                      />
                    )}
                  </FormControl>
                  <FormControl className={styles.formControl} size="small">
                    {!values.image?.mobile?.filename && (
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.image?.mobile?.url && touched.image?.mobile?.url)}
                        name="image.mobile.url"
                        margin="dense"
                        label="Ссылка на мобиль. изображение(необязательное поле)"
                        value={values.image?.mobile?.url}
                        placeholder="Ссылка на мобиль. изображение"
                      />
                    )}
                    <div className={styles.errorText}>
                      {touched.image?.mobile?.url || touched.image?.mobile?.filename}
                    </div>
                    {!values.image.url && (
                      <UploadImage
                        onUpload={(files) => onUpload(files, values.image, setFieldValue, 'mobile')}
                        onImageRemove={() =>
                          setFieldValue('image', {
                            desktop: values.image.desktop,
                            mobile: { ...initialImageValue },
                          })
                        }
                        image={values.image.mobile}
                      />
                    )}
                  </FormControl>

                  {values.type === bannerTypes.stream && (
                    <FormControl className={styles.formControl} size="small">
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.date && touched.date)}
                        name="date"
                        type="datetime-local"
                        margin="dense"
                        value={values.date}
                        placeholder="Дата"
                      />
                      <div style={{ color: 'red', fontSize: 12 }}>
                        {touched.date ? errors.date : ''}
                      </div>
                    </FormControl>
                  )}
                  <FormControl className={styles.formControl} size="small">
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.order && touched.order)}
                      name="order"
                      margin="dense"
                      label="Порядок игры"
                      value={values.order}
                      placeholder="Порядок игры"
                      type="number"
                    />
                    <div className={styles.errorText}>{touched.order ? errors.order : ''}</div>
                  </FormControl>
                  <FormControl className={styles.formControl} size="small">
                    <div>
                      <Checkbox
                        checked={Boolean(values.enabled)}
                        name="enabled"
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        label="Включено?"
                      />
                    </div>
                    <Typography style={{ marginRight: 10 }}>Включено?</Typography>
                  </FormControl>
                  <DialogActions>
                    <Button color="primary" variant="contained" type="submit" disabled={isLoading}>
                      {mode === 'create' ? 'Создать' : 'Обновить'}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateOrUpdateBannerModal;
