import React from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export default function W2({ width, height }) {
  return (
    <SVGUniqueID>
      <svg
        id='w-02_svg__Layer_1'
        x={0}
        y={0}
        viewBox='0 0 70 70'
        xmlSpace='preserve'
        width={width}
        height={height}
      >
        <style>{'.w-02_svg__st1{fill:#231f20}'}</style>
        <linearGradient
          id='w-02_svg__SVGID_1_'
          gradientUnits='userSpaceOnUse'
          x1={35}
          y1={69.5}
          x2={35}
          y2={0.5}
        >
          <stop offset={0} stopColor='#bcc5c7' />
          <stop offset={0.65} stopColor='#e6e8e9' />
        </linearGradient>
        <path
          d='M10 69.5C4.8 69.5.5 65.2.5 60V10C.5 4.8 4.8.5 10 .5h50c5.2 0 9.5 4.3 9.5 9.5v50c0 5.2-4.3 9.5-9.5 9.5H10z'
          fill='url(#w-02_svg__SVGID_1_)'
        />
        <path
          d='M60 1c5 0 9 4 9 9v50c0 5-4 9-9 9H10c-5 0-9-4-9-9V10c0-5 4-9 9-9h50m0-1H10C4.5 0 0 4.5 0 10v50c0 5.5 4.5 10 10 10h50c5.5 0 10-4.5 10-10V10c0-5.5-4.5-10-10-10z'
          fill='#fff'
        />
        <g>
          <linearGradient
            id='w-02_svg__SVGID_2_'
            gradientUnits='userSpaceOnUse'
            x1={11.757}
            y1={36.032}
            x2={58.241}
            y2={36.032}
          >
            <stop offset={0} stopColor='#f7f5d2' />
            <stop offset={1} stopColor='#d7d4b7' />
          </linearGradient>
          <path
            d='M25 58.8v-3.5s.1-3.2 5.9-8.4c6.3-5.6 4.9-9.2 4.9-9.4l-.3-.6-.6.4s-4 2.6-7.9 3.3h-.2l-2.3 2.3v.2c0 .2-.6 5-4.7 5h-.6c-.3-.2-.9-.6-1.6-.6-.5 0-1 .2-1.5.5-.7-.2-2.7-.9-3.7-2.6-.7-1.2-.9-2.8-.3-4.6 4.1-6.8 6.1-10.5 6.1-11 0-2.8.4-4 .6-4.5.6-1.3 2.6-3.2 2.6-3.2l.2-.2v-2.4c-.5-2.4-1-5.3-.8-6.1 1.3.2 5.3 2.8 5.8 3.8l.5 1 .5-1c.4-.7 1.5-2.6 2.4-3.1.6 0 2.1 2.1 3.3 4.5l.2.3h1c3.3 0 20.1 1.1 22.4 21.8 0 .1 1.3 11 1.3 18.3H25z'
            fill='url(#w-02_svg__SVGID_2_)'
          />
          <linearGradient
            id='w-02_svg__SVGID_3_'
            gradientUnits='userSpaceOnUse'
            x1={35.012}
            y1={61.287}
            x2={35.012}
            y2={10.465}
          >
            <stop offset={0.001} stopColor='#fff' />
            <stop offset={1} stopColor='#a4b0b2' />
          </linearGradient>
          <path
            d='M20.7 13.3c1.3.2 5.3 2.8 5.8 3.8l.5 1 .5-1c.4-.7 1.5-2.6 2.4-3.1.6 0 2.1 2.1 3.3 4.5l.2.3h1c3.3 0 20.1 1.1 22.4 21.8 0 .1 1.3 11 1.3 18.3H25v-3.5s.1-3.2 5.9-8.4c6.3-5.6 4.9-9.2 4.9-9.4l-.3-.6-.6.4s-4 2.6-7.9 3.3h-.2L24.5 43v.2c0 .2-.6 5-4.7 5h-.6c-.3-.2-.9-.6-1.6-.6-.5 0-1 .2-1.5.5-.7-.2-2.7-.9-3.7-2.6-.7-1.2-.9-2.8-.3-4.6 4.1-6.8 6.1-10.5 6.1-11 0-2.8.4-4 .6-4.5.6-1.3 2.6-3.2 2.6-3.2l.2-.2v-2.4c-.6-2.6-1-5.5-.9-6.3m-2-2.8l-.4 2.4c-.3 1.6.5 5.7.8 6.9v1c-.7.8-1.9 2.1-2.5 3.3-.6 1.1-.8 2.8-.9 5.2-.3.6-1.4 2.8-5.8 10.1l-.2.3v.3c-.9 3.1-.2 5.3.6 6.6 1.5 2.5 4.1 3.4 5.2 3.7l1.3.4.9-.8.6.5.7.1h.8c4.1 0 6.5-3.2 7.1-6.5l1.2-1.2c1.5-.3 3-.9 4.3-1.5-.6 1-1.6 2.2-3.2 3.6-6.3 5.6-6.7 9.5-6.7 10.2v6h38.3v-2.5c-.1-7.3-1.3-18.1-1.4-18.5-1.4-11.8-7.3-17.6-12.1-20.4-4.9-2.9-9.9-3.4-12.3-3.4-2.6-4.8-4.3-4.8-5-4.8h-.6l-.5.3c-.8.4-1.6 1.2-2.2 2-1.8-1.4-4.3-2.8-5.6-3l-2.4-.3z'
            fill='url(#w-02_svg__SVGID_3_)'
          />
          <path
            className='w-02_svg__st1'
            d='M21.3 14c1.5.6 4.4 2.6 4.8 3.3l1 2 1-2c.4-.9 1.3-2.2 2-2.7.6.5 1.8 2.3 2.8 4.1l.3.7h1.2c1.4 0 6.5.2 11.3 3 6 3.5 9.6 9.6 10.6 18.2 0 .1 1.2 10.4 1.3 17.6H25.5v-2.9s.2-3.1 5.7-8c6.5-5.8 5.2-9.6 5-10l-.5-1.3-1.2.8s-3.9 2.6-7.7 3.2l-.4.1-.3.3-2 2-.1.2v.4c0 .2-.6 4.5-4.1 4.5h-.4c-.4-.3-1-.6-1.8-.6-.4 0-1 .1-1.6.5-.8-.3-2.3-.9-3.1-2.3-.7-1.1-.8-2.5-.3-4.1 6.2-10.3 6.2-10.9 6.2-11.3 0-2.7.3-3.8.6-4.2.5-1 2-2.6 2.5-3.1l.3-.3v-2.7c-.6-2-1-4.2-1-5.4m-.7-1.3c-.1 0-.2 0-.3.1-.7.7.7 6.7.7 6.7v2s-2 2-2.7 3.4c-.7 1.3-.7 4-.7 4.7 0 .7-6.1 10.8-6.1 10.8-2 6.7 4.7 8.1 4.7 8.1.4-.4.9-.6 1.3-.6.8 0 1.4.6 1.4.6.3 0 .6.1.8.1 4.7 0 5.3-5.5 5.3-5.5l2-2c4-.7 8.1-3.4 8.1-3.4s1.3 3.4-4.7 8.8c-6.1 5.4-6.1 8.8-6.1 8.8v4h34.4c0-7.4-1.3-18.9-1.3-18.9-2.4-21.2-19.5-22.3-23-22.3h-.6s-2.4-4.8-3.8-4.8c-.1 0-.2 0-.3.1-1.3.7-2.7 3.4-2.7 3.4-.5-1.2-5.1-4.1-6.4-4.1z'
          />
          <path
            className='w-02_svg__st1'
            d='M36.5 29s.5 3.2-.8 4.9c-.4.5-.6 1.2-.4 1.9.1.4.3.7.5.7.7 0 2 .7 2-7.4h-1.3zM21 43.2s-.7 2-2 2c0 0 0 2 1.3.7s2.7-2.7.7-2.7zM15.6 40.5s-2 2 .7 2l1.3-1.3c0-.1.7-2.8-2-.7zM25.7 24.3l-4 4s0 2.7 1.3.7h.3c.3.4.8.7 1.3.7.9 0 1.7-.8 1.7-1.7 0-.6-.2-1.4-.7-1.7l.7-.7c.1 0 2.1-1.3-.6-1.3z'
          />
        </g>
      </svg>
    </SVGUniqueID>
  )
}
