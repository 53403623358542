import endpoints from 'api/endpoints';
import { api } from 'api/rest';

export class ExternalGameProviderService {
  static async findAll() {
    const response = await api.get(endpoints.externalGameProviders);
    return response;
  }
  static async create(data) {
    const response = await api.post(endpoints.externalGameProviders, data);
    return response;
  }
  static async delete(id) {
    const response = await api.delete(`${endpoints.externalGameProviders}/${id}`);
    return response;
  }
  static async update(id, data) {
    const response = await api.put(`${endpoints.externalGameProviders}/${id}`, data);
    return response;
  }
}
