import endpoints from 'api/endpoints';
import { api } from 'api/rest';

export class LanguageService {
  static async exportTranslations(language) {
    const response = await api.post(endpoints.exportTranslations, { language });
    return response;
  }

  static async updateLocale(data: Record<string, any>) {
    const response = await api.post(`${endpoints.languages}/${data.language}`, data);
    return response;
  }
}
