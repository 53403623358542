import { EGameFen, EGameType } from './game.types';

export const mapFenTypeMapper = {
  [EGameFen.FEN_NORMAL]: 'Классический',
  [EGameFen.FEN_960]: '960',
};

export const mapFenTypeToText = (val: EGameFen) => {
  return mapFenTypeMapper[val];
};
export const mapTypeMapper = {
  [EGameType.CASH]: 'Обычный',
  [EGameType.MATCH]: 'Матч',
  [EGameType.TOURNAMENT]: 'Турнирный',
};
export const mapTypeToText = (val: EGameType) => {
  return mapTypeMapper[val];
};
