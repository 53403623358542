import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  scroller: {
    maxHeight: '100%',
    overflowY: 'auto',
    borderRadius: 5,
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#fff',
      borderRadius: '10px',
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    maxWidth: 400,
    width: '100%',
    background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
    boxShadow: 'none',
    border: 'none',
    padding: '20px',
  },
  emoji: {
    margin: '5px 5px',
    cursor: 'pointer',
    fontSize: '18px',
  },
});
